import { useEffect } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import { getFullName } from "../../utils/helper";

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const VerifyContainer = styled.div`
  height: 100%;
  background: #f7fafb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyText = styled.p`
  font-size: 36px;
`;

const TextLoader = ({ loading }) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={"#2f394e"}
        loading={loading}
        css={customCss}
      />
    </div>
  );
};

const VerifyUser = ({ history, location, verify, verifyUser, resetVerify }) => {
  useEffect(() => {
    let token = localStorage.getItem("access_token");

    if (token != null) {
      history.push("/user/dashboard");
    }
    if (!location.search) {
      history.push("/auth/login");
    } else {
      const queryParsed = queryString.parse(location.search);
      let payload = {
        email: queryParsed.email,
        code: queryParsed.code,
      };
      verify(payload);
    }
  }, []);

  useEffect(() => {
    if (verifyUser && verifyUser.redirect) {
      if (verifyUser.token && verifyUser.userData) {
        localStorage.setItem("access_token", verifyUser.token);
        localStorage.setItem("user_id", verifyUser.userData.user_id);
        localStorage.setItem(
          "user_profile",
          verifyUser.userData.user_profile.id
        );
        localStorage.setItem(
          "user_fullName",
          getFullName(verifyUser.userData.user_profile)
        );
        if (verificationCode.userData.user_profile.color) {
          localStorage.setItem(
            "user_profile_color",
            verificationCode.userData.user_profile.color
          );
        }
        localStorage.setItem("email", verifyUser.userData.email);
        setTimeout(() => {
          if (verifyUser.userData.show_onboard) {
            history.push({
              pathname: "/setup/onboarding",
              state: {
                pending_invitation: verifyUser.userData.pending_invitation,
              },
            });
          } else {
            history.push("/user/dashboard");
          }
        }, 400);
      } else {
        history.push("/auth/login");
      }
      setTimeout(() => {
        resetVerify();
      }, 500);
    } else if (verifyUser && verifyUser.failure && location.search) {
      const queryParsed = queryString.parse(location.search);
      history.push(`/auth/verify-code?email=${queryParsed.email}`);
      resetVerify();
    }
  }, [verifyUser]);

  return (
    <VerifyContainer>
      <VerifyText>Verifying</VerifyText>
      <TextLoader loading />
    </VerifyContainer>
  );
};

export default VerifyUser;
