import React from "react";

import {
  IntegrationCard,
  CardIcon,
  ConnectButton,
} from "../../styledComponents/integrations";
import {
  CommonText,
} from "../../styledComponents/common";

import asanaIcon from "../../assets/img/integrations/asana.svg";

const SingleIntegrationCard = (props) => {
  const {
    integration,
    history,
    selectedOrganization,
    connectIntegration,
    handleViewDetails,
  } = props;

  return (
    <IntegrationCard>
      <CardIcon src={integration.icon} alt={`${integration.name}Icon`} />
      <CommonText fontWeight="600" margin="10px 0" fontSize="15px">
        {integration.display_name}
      </CommonText>
      <ConnectButton
        onClick={() => {
          if (integration.is_installed) {
            handleViewDetails(integration.id);
          } else {
            connectIntegration(integration);
          }
        }}
      >
        {integration.is_installed ? "View Details" : "Connect"}
      </ConnectButton>
    </IntegrationCard>
  );
};

const AsanaCard = (props) => {
  const { history, selectedOrganization, isInstalledAsana } = props;

  const asanaIntegration = {
    id: "asana",
    name: "asana",
    tag: "asana",
    display_name: "Asana (New)",
    description: "Apploye to Asana Integration",
    is_installed: isInstalledAsana,
    icon: asanaIcon,
    frontend_steps: []
  };

  const connectAsanaIntegration = () => {
    if (selectedOrganization && selectedOrganization.id) {
      history.push({
        pathname: `/user/integrations/asana-request-access`,
      });
    }
  };

  const handleAsanaViewDetails = (integrationId) => {
    if (selectedOrganization && selectedOrganization.id) {
      history.push({
        pathname: `/user/integrations/asana-details`,
      });
    }
  };

  return (
    <SingleIntegrationCard
      integration={asanaIntegration}
      history={history}
      selectedOrganization={selectedOrganization}
      handleViewDetails={handleAsanaViewDetails}
      connectIntegration={connectAsanaIntegration}
    />
  );
};

export default AsanaCard;
