export const clearLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_profile");
  localStorage.removeItem("user_profile_color");
  localStorage.removeItem("user_fullName");
  localStorage.removeItem("selected_organization");
  localStorage.removeItem("selected_organization_id");
  localStorage.removeItem("selected_organization_role");
  localStorage.removeItem("selected_organization_plan");
  localStorage.removeItem("email");
  localStorage.removeItem("created_at");
  localStorage.removeItem("is_owner");
  localStorage.removeItem("user_timezone");
};
