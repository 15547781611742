import styled from "styled-components";
import { NextButtonIcon } from "../onboardingStepsStyles";

export const PendingInvitationContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 74%;
  padding: 20px;
  margin-right: 26%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const PendingInvitationContent = styled.div`
  max-width: 580px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const PendingInvitationTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.main.textMain};
`;
export const PendingInvitationSubtitle = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;
export const PendingInvitationDescription = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  text-align: center;
  // max-width: 90%;
  margin: 0 auto;
`;

export const AcceptInvitationButton = styled.div`
  background-color: ${({ theme }) => theme.colors.main.primary};
  color: ${({ theme }) => theme.colors.main.white};
  font-size: 15px;
  font-weight: 600;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.primary};
    ${NextButtonIcon} {
      transform: translateX(4px);
      transition: transform 0.3s ease;
    }
  }
`;
export const DeclineInvitationLink = styled.p`
  position: absolute;
  bottom: 10px;
  right: 40px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
export const SignOutLink = styled.p`
  margin: 20px 0 0;
  color: ${({ theme }) => theme.colors.main.labelText};
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.main.textSecondary};
  }
`;
