import { connect } from 'react-redux';
import AsanaIntegration from './AsanaIntegration';

import { updateAsanaCredentials, getAsanaCredentials } from './asanaIntegrationsActions';

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.asanaIntegrations.isLoading,
  syncIsLoading: state.asanaIntegrations.syncIsLoading,
  asanaInfo: state.asanaIntegrations.asanaInfo,
  asanaCredentials: state.asanaIntegrations.asanaCredentials,
})

const mapDispatchToProps = (dispatch) => ({
  updateAsanaCredentials: (payload) => 
    dispatch(updateAsanaCredentials(payload)),
  getAsanaCredentials: (payload) => 
    dispatch(getAsanaCredentials(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AsanaIntegration)