import { useState, useEffect } from "react";
import {
  GeneralDetailsContainer,
  GeneralDetailsTitleContainer,
  GeneralDetailsInputContainer,
  FormAlert,
  DescriptionDiv,
  IconTitleSection,
  CollapseRotate,
  TitleContainer,
} from "../../../styledComponents/createProject";
import {
  InputWithLabelSection,
  InputLabel,
  InputField,
  InputTextArea,
  CommonFlex,
  CardTitle,
} from "../../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../../styledComponents/buttons";

import moment from "moment";
import DatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import Select from "../../../components/ReactSelectDropdown";
import CreateProjectDropdown from "../../../components/DropdownStyle/CreateProjectDropdown";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import { checkUrl, checkHtml, getDateLabel } from "../../../utils/helper";

import generalDetailsIcon from "../../../assets/img/icons/general-details.svg";
import Collapse from "../../../assets/img/icons/collapse.svg";
import { useDebouncedCallback } from "use-debounce";

const GeneralDetails = (props) => {
  const [projectName, setProjectName] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const [selectClient, setSelectClient] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [startDate, setStartDate] = useState(moment(new Date()));
  const [deadline, setDeadline] = useState(null);
  const [status, setStatus] = useState("");
  const statusOptions = [
    { value: "open", label: "Open" },
    { value: "in progress", label: "In Progress" },
    { value: "complete", label: "Complete" },
  ];
  const [description, setDescription] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [projectCreated, setProjectCreated] = useState(false);

  useEffect(() => {
    return () => {
      setProjectCreated(false);
    };
  }, []);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getClientList(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      let options = props.clientList
        .filter((item) => item.is_active)
        .map((item) => ({
          value: item.id,
          label: item.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setClientOptions(options);
    }
  }, [props.clientList]);

  useEffect(() => {
    if (props.projectId) {
      setProjectCreated(true);
      props.toggleGeneralDetails();
      props.toggleAddPeople();
      window.scrollBy({
        top: 90,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [props.projectId]);

  useEffect(() => {
    if (props.generalDetailsActive) {
      setProjectCreated(false);
    }
  }, [props.generalDetailsActive]);

  const onProjectNameChange = (e) => {
    const { value } = e.target;
    let errors = { ...saveErrors };
    delete errors["projectName"];
    setSaveErrors(errors);
    setProjectName(value);
  };

  const onProjectCodeChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["code"];
    setSaveErrors(errors);
    setProjectCode(e.target.value);
  };

  const onProjectDescriptionChange = (e) => {
    let errors = { ...saveErrors };
    delete errors["description"];
    setSaveErrors(errors);
    setDescription(e.target.value);
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
  };

  const onDeadlineChange = (date) => {
    let errors = { ...saveErrors };
    delete errors["deadline"];
    setSaveErrors(errors);
    setDeadline(date);
  };

  const onStatusChange = (e) => {
    setStatus(e);
  };

  const checkError = () => {
    let formIsValid = true;
    let saveErrors = {};
    if (projectName === "") {
      saveErrors["projectName"] = "Project name can not be empty";
      formIsValid = false;
    } else if (projectName && projectName.length < 3) {
      saveErrors["projectName"] =
        "Project name must have at least 3 characters";
      formIsValid = false;
    } else if (projectName && projectName.length > 256) {
      saveErrors["projectName"] =
        "Project name can have maximum 256 characters";
      formIsValid = false;
    } else if (checkUrl(projectName)) {
      saveErrors["projectName"] = "Project name can not contain url.";
      formIsValid = false;
    } else if (checkHtml(projectName)) {
      saveErrors["projectName"] = "Invalid project name.";
      formIsValid = false;
    }

    if (projectCode && projectCode.length > 10) {
      saveErrors["code"] = "Type project code within 10 characters";
      formIsValid = false;
    } else if (checkUrl(projectCode)) {
      saveErrors["code"] = "project code can not contain url.";
      formIsValid = false;
    } else if (checkHtml(projectCode)) {
      saveErrors["code"] = "Invalid project code.";
      formIsValid = false;
    }

    if (deadline && startDate && moment(deadline).isBefore(startDate)) {
      saveErrors["deadline"] = "Deadline can not be before start date.";
      formIsValid = false;
    }

    if (description && description.length > 512) {
      saveErrors["description"] =
        "Project Description can have maximum 512 characters";
      formIsValid = false;
    } else if (checkUrl(description)) {
      saveErrors["description"] = "Description can not contain url.";
      formIsValid = false;
    } else if (checkHtml(description)) {
      saveErrors["description"] = "Invalid text in description.";
      formIsValid = false;
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleSave = useDebouncedCallback(() => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          name: projectName,
          description: description ? description : null,
          code: projectCode ? projectCode : null,
          start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          deadline: deadline ? moment(deadline).format("YYYY-MM-DD") : null,
          status: status ? status.value : "open",
        };
  
        if (selectClient && selectClient.value) {
          payload.client_id = selectClient.value;
        }
  
        if (props.projectId) {
          payload.project_id = props.projectId;
          props.updateProject(payload);
          props.setGeneralInfoSaved(true);
        } else {
          props.createProject(payload);
          props.setGeneralInfoSaved(true);
        }
      }
    }
  }, 3000, { leading: true, trailing: false });

  const cancelFunction = () => {
    setProjectName("");
    setProjectCode("");
    setSelectClient("");
    setStartDate("");
    setDeadline("");
    setStatus("");
    setDescription("");
    props.history.push("/user/projects");
  };

  const handleSelectClient = (e) => {
    setSelectClient(e);
  };

  return (
    <>
      <TitleContainer
        padding="5px 30px 10px"
        onClick={() => props.toggleGeneralDetails()}
      >
        <IconTitleSection>
          <div>
            <img src={generalDetailsIcon} alt="" width="27px" />
          </div>
          <GeneralDetailsTitleContainer>
            <CardTitle fontSize="16px">General details</CardTitle>
            {/* {projectCreated && (
              <FormAlert
                style={{
                  color: "#20bead",
                  fontSize: "14px",
                  fontWeight: "600",
                  marginLeft: "40px",
                  marginTop: "0px",
                }}
              >
                Project has been saved!
              </FormAlert>
            )} */}
          </GeneralDetailsTitleContainer>
        </IconTitleSection>
        <CollapseRotate active={props.generalDetailsActive}>
          <img src={Collapse} alt="collapse" width="100%" />
        </CollapseRotate>
      </TitleContainer>
      <GeneralDetailsContainer
        active={props.generalDetailsActive} /*active={false}*/
      >
        <GeneralDetailsInputContainer>
          <InputWithLabelSection>
            <InputLabel>Project name</InputLabel>
            <InputField
              type="text"
              placeholder="Write project name"
              value={projectName}
              onChange={(e) => onProjectNameChange(e)}
              error={
                saveErrors &&
                saveErrors["projectName"] !== undefined &&
                saveErrors["projectName"] &&
                saveErrors["projectName"]
              }
            />
            {saveErrors &&
              saveErrors["projectName"] &&
              saveErrors["projectName"] !== undefined && (
                <FormAlert>{saveErrors["projectName"]}</FormAlert>
              )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Project Code (Optional)</InputLabel>
            <InputField
              type="text"
              placeholder="Write project code"
              value={projectCode}
              onChange={(e) => onProjectCodeChange(e)}
              error={
                saveErrors &&
                saveErrors["code"] !== undefined &&
                saveErrors["code"] &&
                saveErrors["code"]
              }
            />
            {saveErrors &&
              saveErrors["code"] &&
              saveErrors["code"] !== undefined && (
                <FormAlert>{saveErrors["code"]}</FormAlert>
              )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Select client (Optional)</InputLabel>
            <Select
              isSearchable
              value={selectClient}
              options={clientOptions}
              placeholder="Choose client"
              onChange={(e) => handleSelectClient(e)}
              styles={CreateProjectDropdown(null)}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>
              Project Start Date
              <span style={{ color: "red" }}>
                {getDateLabel({
                  startDate: startDate,
                  endDate: startDate,
                  onlyToday: true,
                })}
              </span>
            </InputLabel>
            <DatePicker
              id="createStartDate"
              date={startDate}
              onDateChange={onStartDateChange}
              dateDisplayFormat={"DD/MM/YYYY"}
              allowPreviousDates
              placeholder={"Select start date"}
            />
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>
              Deadline{" "}
              {getDateLabel({
                startDate: deadline,
                endDate: deadline,
                onlyToday: true,
              })}
            </InputLabel>
            <div className="deadline">
              <DatePicker
                id="createDeadline"
                date={deadline}
                onDateChange={onDeadlineChange}
                disablePreviousDatesFrom={startDate}
                dateDisplayFormat={"DD/MM/YYYY"}
                placeholder={"Select deadline"}
                error={
                  saveErrors &&
                  saveErrors["deadline"] !== undefined &&
                  saveErrors["deadline"] &&
                  saveErrors["deadline"]
                }
              />
            </div>
            {saveErrors &&
              saveErrors["deadline"] !== undefined &&
              saveErrors["deadline"] && (
                <FormAlert>{saveErrors["deadline"]}</FormAlert>
              )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>Status</InputLabel>
            <Select
              isSearchable={false}
              value={status}
              options={statusOptions}
              placeholder="Choose status"
              onChange={(e) => onStatusChange(e)}
              styles={CreateProjectDropdown(null)}
            />
          </InputWithLabelSection>
        </GeneralDetailsInputContainer>
        <DescriptionDiv>
          <InputWithLabelSection>
            <InputLabel>Description</InputLabel>
            <InputTextArea
              text="Description"
              cols="40"
              rows="5"
              value={description}
              placeholder="You can write notes to keep trace about project goals..."
              onChange={(e) => onProjectDescriptionChange(e)}
              error={
                saveErrors &&
                saveErrors["description"] !== undefined &&
                saveErrors["description"] &&
                saveErrors["description"]
              }
            ></InputTextArea>
            {saveErrors &&
              saveErrors["description"] &&
              saveErrors["description"] !== undefined && (
                <FormAlert>{saveErrors["description"]}</FormAlert>
              )}
          </InputWithLabelSection>
        </DescriptionDiv>
        <CommonFlex padding="10px 30px" gap="15px">
          <WhiteButton
            padding="0 30px"
            type="cancel"
            onClick={() => cancelFunction()}
          >
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={
              props.createProjectIsLoading || props.updateProjectIsLoading
                ? "0 20px"
                : "0 40px"
            }
            onClick={handleSave}
          >
            {props.createProjectIsLoading || props.updateProjectIsLoading ? (
              <ButtonTextLoader loadingText="Saving" fontSize="13px" />
            ) : (
              "Save"
            )}
          </PrimaryButton>
        </CommonFlex>
      </GeneralDetailsContainer>
    </>
  );
};

export default GeneralDetails;
