import { useState, useEffect } from "react";
import moment from "moment-timezone";

import {
  Container,
  TableText,
  RoundMiniImage,
} from "../../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  SecondaryButton,
} from "../../../styledComponents/buttons";
import { TableItem } from "../../../styledComponents/teams";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../../styledComponents/createProject";
import {
  LiveMembersTableHeadContainer,
  LiveMembersTableContainer,
  UserDotDiv,
  UserDotCircle,
} from "./currentlyTrackingStyles";

import { PulsatingDot } from "./CurrentlyTracking";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import TakeScreenshotPopup from "../InstantScreenshots/TakeScreenshotPopup";

import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
import { getFullName } from "../../../utils/helper";

export const UserSection = (props) => {
  return (
    <AssigneesManageImgTextGrid>
      <AssigneesManageRoundImage
        imageSize={props.size}
        style={{ position: "relative" }}
      >
        {props.imgSource ? (
          <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
        ) : (
          <FirstRoundLetterComp
            size="36px"
            text={props.name}
            backColor={props.index}
            color={props.color}
          />
        )}
        <UserDotDiv>
          <UserDotCircle />
        </UserDotDiv>
      </AssigneesManageRoundImage>

      <TableText name>{props.name}</TableText>
    </AssigneesManageImgTextGrid>
  );
};

const CurrentlyTrackingList = (props) => {
  const [membersList, setMembersList] = useState([]);
  const [dontShowWarning, setDontShowWarning] = useState(false);
  const [isScreenshotModalOpen, setIsScreenshotModalOpen] = useState(false);
  const [requestedScreenshotUserId, setRequestedScreenshotUserId] =
    useState(null);

  const {
    liveMembersList,
    isLoading,
    selectedOrganization,
    searchTerm,
    takeInstantScreenshot,
    history,
  } = props;

  useEffect(() => {
    if (liveMembersList && liveMembersList.length > 0) {
      const list = [];
      liveMembersList.forEach((member) => {
        list.push({
          user_id: member.user.id,
          name: getFullName(member.user),
          avatar: member.user.avatar,
          color: member.user.color,
          project: member.project,
          task: member.task,
          start_timestamp: member.start_timestamp,
        });
      });
      setMembersList(list);
    } else if (liveMembersList && liveMembersList.length === 0) {
      setMembersList([]);
    }
  }, [liveMembersList]);

  const handleInstantScreenshotRequest = (userId) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        for_user_id: userId,
      };
      takeInstantScreenshot(payload);
      if (dontShowWarning) {
        localStorage.setItem("dont_show_instant_screenshot_alert", true);
      }
      setTimeout(() => {
        history.push("/user/instant-screenshots");
      }, 400);
    }
  };

  const handleTakeScreenshot = (userId) => {
    if (
      selectedOrganization &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.ask_instant_screenshot_permission &&
      !localStorage.getItem("dont_show_instant_screenshot_alert")
    ) {
      setRequestedScreenshotUserId(userId);
    } else {
      handleInstantScreenshotRequest(userId);
    }
  };

  return (
    <Container style={{ paddingBottom: "20px" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : membersList && membersList.length > 0 ? (
        <>
          <TakeScreenshotPopup
            dontShowWarning={dontShowWarning}
            setDontShowWarning={setDontShowWarning}
            isOpen={requestedScreenshotUserId}
            requestedScreenshotUserId={requestedScreenshotUserId}
            toggle={() => setRequestedScreenshotUserId(null)}
            handleInstantScreenshotRequest={handleInstantScreenshotRequest}
            history={history}
          />
          <LiveMembersTableHeadContainer>
            <TableItem>
              <TableText>Member</TableText>
            </TableItem>

            <TableItem>
              <TableText>Project</TableText>
            </TableItem>
            <TableItem>
              <TableText>Task</TableText>
            </TableItem>
            <TableItem>
              <TableText>Latest Start Time</TableText>
            </TableItem>
            <TableItem>
              <TableText></TableText>
            </TableItem>
          </LiveMembersTableHeadContainer>
          {membersList.map((trackingData, index) => (
            <LiveMembersTableContainer key={index}>
              <TableItem>
                <UserSection
                  imgSource={trackingData.avatar || null}
                  name={trackingData.name}
                  size="36px"
                  index={index}
                  color={trackingData.color || null}
                />
              </TableItem>
              <TableItem>
                <TableText name>{trackingData.project?.name}</TableText>
              </TableItem>
              <TableItem>
                <TableText name>
                  {trackingData.task && trackingData.task.id
                    ? trackingData.task.name
                    : "-"}
                </TableText>
              </TableItem>
              <TableItem>
                <TableText name>
                  {moment.unix(trackingData.start_timestamp).format("hh:mm a")}
                </TableText>
              </TableItem>

              <TableItem>
                {selectedOrganization &&
                  selectedOrganization.configuration &&
                  selectedOrganization.configuration.screenshot_track && (
                    <SecondaryButton
                      type="blue"
                      padding="0 10px"
                      onClick={() => handleTakeScreenshot(trackingData.user_id)}
                    >
                      Take Screenshot
                    </SecondaryButton>
                  )}
              </TableItem>
            </LiveMembersTableContainer>
          ))}
        </>
      ) : (
        <NoDataComponent
          title={
            searchTerm
              ? "No member is tracking with this name"
              : "Seems no one is tracking right now"
          }
        />
      )}
    </Container>
  );
};

export default CurrentlyTrackingList;
