import { useState, useEffect, useRef, Fragment } from "react";
import moment from "moment";
import {
  AdditionalContainer,
  CollapseContainer,
  TitleContainer,
  IconTitleSection,
  Cross,
  CrossIcon,
  CollapseRotate,
  AddtaskContainer,
  AddTaskComponent,
  InputField,
  SetRateButton,
  AddTaskLink,
  AssigneeRoundImageSection,
  AssigneeRoundImage,
  RoundImageCross,
  RoundPersonImage,
  MainText,
} from "../../../styledComponents/createProject";
import {
  CommonText,
  CardTitle,
  AssignListContainer,
  AssignListItem,
  AssignListText,
  SearchBarWithAssignListContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchBarContainer,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  ShowMoreAssigneeList,
  FormAlert,
} from "../../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../../styledComponents/buttons";
import {
  CreateTaskContainer,
  CreateTaskInput,
  CreateTaskButtonContainer,
} from "../../../styledComponents/tasks";

import { checkUrl, checkHtml, getFullName } from "../../../utils/helper";

import ProjectTask from "../../../assets/img/icons/project_task.svg";
import Collapse from "../../../assets/img/icons/collapse.svg";
import Assign from "../../../assets/img/icons/assign2.svg";
import removeAssignee from "../../../assets/img/icons/remove_assignee.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import searchIcon from "../../../assets/img/icons/search.svg";

import AssigneeTooltip from "../../../components/Tooltip/AssigneeTooltip";
import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";

const AssigneeDropdown = ({
  taskId,
  assigneeManage,
  updateState,
  assigneeRemove,
  visibilityChange,
  membersList,
  assigneeIsLoading,
  loadingAssigneeId,
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);
  const [addList, setAddList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = membersList;
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          newArr = newArr.filter((item) => item.user.id !== assignee.id);
        });
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
    if (assigneeManage && assigneeManage.length < 4) {
      setMoreAssignee(false);
    }
  }, [membersList, assigneeManage]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== "" &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList = membersList;
      let filteredList = [];
      originalList.forEach((e) => {
        const name = getFullName(e.user);
        if (
          searchTerm &&
          name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push(e);
      });
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          filteredList = filteredList.filter(
            (item) => item.user.id !== assignee.id
          );
        });
      }
      setAddList(filteredList);
    } else if (searchTerm === "") {
      let newArr = membersList;
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          newArr = newArr.filter((item) => item.user.id !== assignee.id);
        });
      }
      setAddList(newArr);
    }
  }, [searchTerm]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2, moreRef1, moreRef2);

  const showAssignee = () => {
    setAddAssignee(true);
  };
  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  const updateAssingeeList = (person) => {
    setAddList(addList.filter((item) => item.id !== person.id));
  };

  function useOutsideClickHandler(ref1, ref2, moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          visibilityChange(false);
        }
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2, moreRef1, moreRef2, addAssignee, moreAssignee]);
  }

  return (
    <AssigneeRoundImageSection
      position={assigneeManage.length}
      style={{ marginRight: `auto` }}
    >
      <AssigneeRoundImage
        index={0}
        onClick={() => {
          showAssignee();
          visibilityChange(true);
        }}
        ref={wrapperRef2}
      >
        <img src={Assign} alt="assign" />
      </AssigneeRoundImage>
      {assigneeManage.length > 3 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
            visibilityChange(true);
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: "3px",
          }}
        >
          {`+${assigneeManage.length - 3}`}
        </AssigneeRoundImage>
      )}
      {moreAssignee && (
        <ShowMoreAssigneeList ref={moreRef1}>
          {assigneeManage.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    size="30px"
                    fontSize="12px"
                    text={getFullName(member)}
                    backColor={index}
                    color={member.color || null}
                  />
                )}

                <RoundImageCross
                  onClick={() => assigneeRemove(taskId, member.id)}
                >
                  <img
                    src={removeAssignee}
                    style={{ width: "100%", height: "100%" }}
                  />
                </RoundImageCross>
              </AssigneeRoundImage>
              <AssignListText>{getFullName(member)}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
      {assigneeManage
        .filter((person, fi) => fi < 3)
        .map((filteredPerson, i) => (
          <AssigneeRoundImage
            key={i}
            index={assigneeManage.length < 4 ? i + 1 : i + 2}
          >
            <AssigneeTooltip toolTipText={getFullName(filteredPerson)} />
            {filteredPerson.avatar ? (
              <RoundPersonImage src={filteredPerson.avatar} alt="addPeople" />
            ) : (
              <FirstRoundLetterComp
                text={getFullName(filteredPerson)}
                backColor={i}
                color={filteredPerson.color || null}
              />
            )}

            <RoundImageCross
              onClick={() => assigneeRemove(taskId, filteredPerson.id)}
            >
              <img
                src={removeAssignee}
                style={{ width: "100%", height: "100%" }}
              />
            </RoundImageCross>
          </AssigneeRoundImage>
        ))}
      {addAssignee && (
        <AssignListContainer ref={wrapperRef1}>
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                  placeholder="Search..."
                  autoFocus
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>

            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => {
                return assigneeIsLoading &&
                  loadingAssigneeId &&
                  loadingAssigneeId === person.user.id ? (
                  <AssignListItem columns="100%" key={i}>
                    <DropdownLoader loading />
                  </AssignListItem>
                ) : (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      updateState(taskId, person.user.id);
                    }}
                    columns="30px auto"
                    padding="7px 8px"
                  >
                    <AssigneeRoundImage index={0} size="30px">
                      {person.user.avatar ? (
                        <RoundPersonImage
                          src={person.user.avatar}
                          alt="addPeople"
                          size="30px"
                          borderColor="#c2cce1"
                        />
                      ) : (
                        <FirstRoundLetterComp
                          size="30px"
                          text={getFullName(person.user)}
                          backColor={i}
                          fontSize="12px"
                          color={person.user.color || null}
                        />
                      )}
                    </AssigneeRoundImage>
                    <AssignListText>{getFullName(person.user)}</AssignListText>
                  </AssignListItem>
                );
              })}
            {assigneeManage &&
              assigneeManage.length === 0 &&
              addList &&
              addList.length === 0 && (
                <AssignListItem columns="100%" padding="7px 8px">
                  <AssignListText>
                    You have not added any assignee to your project. Please add
                    assignees to your project first.
                  </AssignListText>
                </AssignListItem>
              )}
            {assigneeManage &&
              assigneeManage.length > 0 &&
              addList &&
              addList.length === 0 && (
                <AssignListItem columns="100%" padding="7px 8px">
                  <AssignListText>No Options</AssignListText>
                </AssignListItem>
              )}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </AssigneeRoundImageSection>
  );
};

const AddTasks = (props) => {
  const [active, setActive] = useState(false);
  const [visibility, setVisibility] = useState(0);
  const [newTask, setNewTask] = useState(false);
  const [newTaskInput, setNewTaskInput] = useState("");
  const newTaskRef = useRef(null);
  const [oldTaskName, setOldTaskName] = useState("");
  const [taskList, setTaskList] = useState([]);

  const [saveErrors, setSaveErrors] = useState({});

  //function
  useOutsideClickHandler(newTaskRef);

  function useOutsideClickHandler(ref1) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref1.current && !ref1.current.contains(event.target)) {
          setNewTask(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, newTask]);
  }

  useEffect(() => {
    if (props.addTasksActive) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.projectId
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
          status: "to do",
        };
        props.getProjectTaskList(payload);
        props.getProjectAssigneeList(payload);
      }
    }
  }, [props.addTasksActive, props.projectTaskListInProject]);

  useEffect(() => {
    if(props.generalInfoSaved){
      let tasks = [];
      if (props.projectTaskList && props.projectTaskList.length > 0) {
        props.projectTaskList.map((task) => {
          tasks.push({
            id: task.id,
            name: task.name,
            createdAt: moment(task.created_at).format("MMM DD, YYYY"),
            assigneeManage: task.members,
            nameEdit: false,
          });
        });
        setTaskList(tasks);
      } else if (props.projectTaskList && props.projectTaskList.length === 0) {
        setTaskList([]);
      }
    }else{
      setTaskList([]);
    }
  }, [props.projectTaskList]);

  const toggleCollapse = () => {
    setActive(!active);
  };

  const showAssignee = (state) => {
    setVisibility(state);
  };

  const updateAssingeeList = (taskId, memberId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId &&
      taskId &&
      memberId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        task_id: taskId,
        user_id: memberId,
      };
      props.addTaskAssignee(payload);
    }
  };

  const onNewTaskNameInputChange = (e) => {
    setNewTaskInput(e.target.value);
    setSaveErrors({});
  };

  const checkError = () => {
    let formIsValid = true;
    const saveErrors = {};
    if (checkUrl(newTaskInput)) {
      formIsValid = false;
      saveErrors["newTaskInput"] = "Task name can not contain url.";
    } else if (checkHtml(newTaskInput)) {
      formIsValid = false;
      saveErrors["newTaskInput"] = "Invalid task name.";
    }

    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleAddTask = () => {
    if (checkError()) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        props.projectId &&
        newTaskInput
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          project_id: props.projectId,
          name: newTaskInput,
        };
        props.addTask(payload);
      }
      setNewTask(false);
      setNewTaskInput("");
    }
  };

  const handleDeleteTask = (id) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        task_id: id,
      };
      props.deleteTask(payload);
    }
  };

  const handleEditTaskClick = (index) => {
    let newArr = [...taskList];
    newArr[index].nameEdit = !newArr[index].nameEdit;
    newArr
      .filter((fItem, i) => i != index)
      .map((item) => (item.nameEdit = false));
    setTaskList(newArr);
    setOldTaskName(taskList[index].name);
  };

  const editTaskName = (e, index) => {
    const { value } = e.target;
    let newArr = [...taskList];
    newArr[index].name = value;
    setTaskList(newArr);
  };

  const confirmEditTask = (taskId, index) => {
    let newArr = [...taskList];
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId &&
      taskId &&
      newArr[index].name !== ""
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        task_id: taskId,
        name: newArr[index].name,
      };
      props.editTask(payload);
      newArr[index].nameEdit = !newArr[index].nameEdit;
      setTaskList(newArr);
      setOldTaskName("");
    }
  };

  const cancelEditName = (index) => {
    let newArr = [...taskList];
    newArr[index].name = oldTaskName;
    newArr[index].nameEdit = !newArr[index].nameEdit;
    setTaskList(newArr);
    setOldTaskName("");
  };

  const assigneeRemove = (taskId, assigneeId) => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.id &&
      props.projectId &&
      taskId &&
      assigneeId
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        project_id: props.projectId,
        task_id: taskId,
        user_id: assigneeId,
      };
      props.deleteTaskAssignee(payload);
    }
  };

  const cancelNewTask = () => {
    setNewTaskInput("");
    setNewTask(false);
    setSaveErrors({});
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddTask();
    }
  };

  return (
    <AdditionalContainer
      active={props.addTasksActive}
      style={{
        borderTop: props.addTasksActive
          ? `1px solid #20bead`
          : `1px solid #c2cce1`,
      }}
    >
      <TitleContainer onClick={() => props.toggleAddTasks()}>
        <IconTitleSection>
          <div>
            <img src={ProjectTask} alt="addPeople" width="27px" />
          </div>
          <CardTitle fontSize="16px">Add Tasks</CardTitle>
        </IconTitleSection>
        <CollapseRotate active={props.addTasksActive}>
          <img src={Collapse} alt="collapse" width="100%" />
        </CollapseRotate>
      </TitleContainer>
      <CollapseContainer
        active={props.addTasksActive}
        addAssignee={visibility}
        style={{ padding: `0 0px 15px` }}
      >
        {props.taskListLoading ? (
          <ComponentCircleLoader padding="5vh 0" />
        ) : (
          <>
            <AddtaskContainer
              overflow={props.addTasksActive ? "auto" : "hidden"}
              hasTask={taskList && taskList.length > 0 ? true : false}
            >
              {taskList && taskList.length > 0 && (
                <>
                  <CommonText $label>Task Name</CommonText>
                  <CommonText $label>Created On</CommonText>
                  <CommonText $label>Assignees</CommonText>
                </>
              )}
              <div />
              {taskList.map((task, index) => {
                return props.taskIsLoading &&
                  props.loadingTaskId &&
                  props.loadingTaskId === task.id ? (
                  <Fragment key={index}>
                    <DropdownLoader loading />
                    <div />
                    <div />
                    <div />
                  </Fragment>
                ) : (
                  <Fragment key={index}>
                    {task.nameEdit ? (
                      <AddTaskComponent style={{ margin: `0` }}>
                        <InputField
                          height="32px"
                          type="text"
                          placeholder="Write task name"
                          value={task.name}
                          onChange={(e) => editTaskName(e, index)}
                          style={{ maxWidth: "175px" }}
                        />
                        <SetRateButton
                          setRate
                          onClick={() => confirmEditTask(task.id, index)}
                        >
                          Edit Task
                        </SetRateButton>
                        <Cross
                          onClick={() => cancelEditName(index)}
                          size="25px"
                        >
                          <CrossIcon src={crossIcon} alt="" size="18px" />
                        </Cross>
                      </AddTaskComponent>
                    ) : (
                      <MainText onClick={() => handleEditTaskClick(index)}>
                        {task.name}
                      </MainText>
                    )}
                    <CommonText name> {task.createdAt} </CommonText>
                    <AssigneeDropdown
                      taskId={task.id}
                      assigneeManage={task.assigneeManage}
                      updateState={updateAssingeeList}
                      assigneeRemove={assigneeRemove}
                      visibilityChange={showAssignee}
                      membersList={props.projectAssigneeList}
                      assigneeIsLoading={props.taskAssigneeIsLoading}
                      loadingAssigneeId={props.loadingTaskAssigneeId}
                    />
                    <Cross onClick={() => handleDeleteTask(task.id)}>
                      <CrossIcon src={crossIcon} alt="" />
                    </Cross>
                  </Fragment>
                );
              })}
              {/* {newTask ? (
            <AddTaskComponent ref={newTaskRef}>
              <InputField
                type="text"
                placeholder="Write task name"
                value={newTaskInput}
                onChange={(e) => onNewTaskNameInputChange(e)}
              />
              <SetRateButton setRate onClick={() => handleAddTask()}>
                Add Task
              </SetRateButton>
              <Cross onClick={() => cancelNewTask()}>
                <CrossIcon src={crossIcon} alt="" />
              </Cross>
            </AddTaskComponent>
          ) : (
            <AddTaskLink onClick={() => setNewTask(true)}>
              + Add New Task
            </AddTaskLink>
          )} */}
            </AddtaskContainer>
            {newTask ? (
              <CreateTaskContainer
                style={{
                  padding: "15px 30px",
                  borderTop: "1px solid #20bead",
                  borderBottom: "1px solid #20bead",
                }}
              >
                <CreateTaskInput
                  type="text"
                  placeholder="Write task name..."
                  autoFocus
                  value={newTaskInput}
                  onChange={(e) => onNewTaskNameInputChange(e)}
                  onKeyDown={(e) => handleKeyPress(e)}
                  // ref={newTaskRef}
                  style={{ paddingLeft: "0" }}
                />
                <CreateTaskButtonContainer>
                  <WhiteButton type="cancel" onClick={() => cancelNewTask()}>
                    Cancel
                  </WhiteButton>
                  <PrimaryButton
                    disabled={!newTaskInput.trim()}
                    onClick={() => handleAddTask()}
                  >
                    Add Task
                  </PrimaryButton>
                </CreateTaskButtonContainer>
                {saveErrors &&
                  saveErrors["newTaskInput"] &&
                  saveErrors["newTaskInput"] !== undefined && (
                    <FormAlert>{saveErrors["newTaskInput"]}</FormAlert>
                  )}
              </CreateTaskContainer>
            ) : (
              <AddTaskLink onClick={() => setNewTask(true)}>
                + Add New Task
              </AddTaskLink>
            )}
          </>
        )}
      </CollapseContainer>
    </AdditionalContainer>
  );
};

export default AddTasks;
