import { useState, useEffect, useMemo } from "react";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import ProjectsList from "./ProjectsListNew";
import {
  TabSearchGrid,
  ProjectRightButtonContainer,
} from "../../styledComponents/ProjectsNew";
import {
  PageTitle,
  HeaderContainer,
  Container,
  InputField,
  InputWithLabelSection,
  FilterLabel,
} from "../../styledComponents/common";
import {
  ColoredButtonWithIcon,
  ButtonIcon,
  TabButtonContainer,
  TabButton,
} from "../../styledComponents/buttons";
import { ProjectListContainer } from "./projectsStyles";

import SearchWithButton from "../../components/Search/SearchWithButton";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

import ProjectArchiveModal from "./ProjectArchiveModal";
import ProjectDeleteModal from "./ProjectDeleteModal";

import Plus from "../../assets/img/icons/plus_white.svg";
import { setPathName } from "../../filterReducer";
import { useDispatch } from "react-redux";

const Projects = ({
  selectedOrganization,
  getProjectList,
  projectList,
  getClientList,
  clientList,
  getProjectBudgetAndBillable,
  projectBudgetAndBillableIsLoading,
  projectBudgetAndBillable,
  updateProject,
  deleteProject,
  duplicateProject,
  updateProjectIsLoading,
  deleteProjectIsLoading,
  duplicateProjectIsLoading,
  duplicateProjectId,
  history,
  isLoading,
  totalProjectCount,
  projectPageSize,
  reloadProjects,
  filters,
}) => {
  const dispatch = useDispatch();
  const [projectsList, setProjectsList] = useState([]);
  const [projectType, setProjectType] = useState(
    filters?.pathname === "/user/projects" && filters?.projectType
      ? filters?.projectType
      : "active"
  );

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [projectArchiveId, setProjectArchiveId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectDeleteId, setProjectDeleteId] = useState("");

  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(
    filters?.pathname === "/user/projects" && filters?.selectedClient
      ? filters?.selectedClient
      : null
  );

  const [expandedProjectId, setExpandedProjectId] = useState(null);

  const [searchTerm, setSearchTerm] = useState(
    filters?.pathname === "/user/projects" && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : ""
  );
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState(
    filters?.pathname === "/user/projects" && filters?.currentSearchedTerm
      ? filters?.currentSearchedTerm
      : ""
  );

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const callGetProjectList = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        type: projectType || "active",
        page: currentPageNumber,
      };
      if (currentSearchedTerm && currentSearchedTerm.trim()) {
        payload.name = currentSearchedTerm.toLowerCase().trim();
      }
      if (selectedClient && selectedClient.value) {
        payload.client_id = selectedClient.value;
      }
      getProjectList(payload);
    }
  };
  useEffect(() => {
    callGetProjectList();
  }, [
    selectedOrganization,
    selectedClient,
    projectType,
    currentPageNumber,
    currentSearchedTerm,
  ]);
  useEffect(() => {
    reloadProjects && callGetProjectList();
  }, [reloadProjects]);

  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        projectType,
        selectedClient,
        currentSearchedTerm,
      })
    );
  }, [projectType, selectedClient, currentSearchedTerm]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      getClientList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (clientList && clientList.length > 0) {
      const options = [];
      clientList.map((item) => {
        if (item.is_active) {
          options.push({
            value: item.id,
            label: item.name,
          });
        }
      });
      options.unshift({ label: "All Clients", value: "" });
      setClientOptions(options);
    } else if (clientList && clientList.length === 0) {
      setClientOptions([]);
    }
  }, [clientList]);

  useMemo(() => {
    if (selectedOrganization && selectedOrganization.id && expandedProjectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: expandedProjectId,
      };

      getProjectBudgetAndBillable(payload);
    }
  }, [expandedProjectId]);

  const handleSearchByName = () => {
    if (selectedOrganization && selectedOrganization.id) {
      if (searchTerm && searchTerm.trim()) {
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm("");
      }
      setCurrentPageNumber(1);
    }
  };
  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      setCurrentPageNumber(1);

      setSearchTerm("");
      setCurrentSearchedTerm("");
    }
  };

  const onClientSelect = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      if (e && e.value) {
        setSelectedClient(e);
      } else {
        setSelectedClient(null);
      }
      setCurrentPageNumber(1);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const selectProjectType = (value) => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
    }
    setSearchTerm(currentSearchedTerm);
    // setCurrentSearchedTerm("");
    setProjectType(value);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const handleArchiveProject = (action) => {
    if (selectedOrganization && selectedOrganization.id && projectArchiveId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectArchiveId,
        // getAllProjectList: true,
        reloadProjects:
          Math.ceil(totalProjectCount / projectPageSize) !== currentPageNumber,
        type: projectType || "active",
      };
      if (action === "archive") {
        payload.is_active = false;
      } else if (action === "unarchive") {
        payload.is_active = true;
      }
      updateProject(payload);
      setTimeout(() => {
        setIsArchiveModalOpen(!isArchiveModalOpen);
      }, 400);
    }
  };

  const handleDeleteProject = () => {
    if (selectedOrganization && selectedOrganization.id && projectDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectDeleteId,
        type: projectType || "active",
      };
      deleteProject(payload);
      setTimeout(() => {
        setIsDeleteModalOpen(!isDeleteModalOpen);
      }, 300);
    }
  };

  const toggleArchiveModal = (projectId) => {
    if (!isArchiveModalOpen) {
      setProjectArchiveId(projectId);
    }
    setIsArchiveModalOpen(!isArchiveModalOpen);
  };

  const toggleDeleteModal = (projectId) => {
    if (!isDeleteModalOpen) {
      setProjectDeleteId(projectId);
    }
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <div className="content">
      <ProjectArchiveModal
        projectType={projectType}
        isOpen={isArchiveModalOpen}
        toggle={() => setIsArchiveModalOpen(!isArchiveModalOpen)}
        handleArchive={handleArchiveProject}
        isLoading={updateProjectIsLoading}
      />
      <ProjectDeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        handleDelete={handleDeleteProject}
        isLoading={deleteProjectIsLoading}
      />
      <PageTitle>Projects</PageTitle>
      <HeaderContainer>
        <TabSearchGrid>
          <InputWithLabelSection>
            <FilterLabel>Type</FilterLabel>
            <TabButtonContainer columns="auto auto">
              <TabButton
                onClick={() => selectProjectType("active")}
                selected={projectType}
                index={"active"}
              >
                Active
              </TabButton>
              <TabButton
                onClick={() => selectProjectType("archived")}
                selected={projectType}
                index={"archived"}
              >
                Archived
              </TabButton>
            </TabButtonContainer>
          </InputWithLabelSection>

          <SearchWithButton
            itemName="Project"
            searchTermName="name & code"
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          />
          {selectedOrganization && selectedOrganization.role !== "member" && (
            <InputWithLabelSection>
              <FilterLabel>Client</FilterLabel>
              <Select
                value={selectedClient}
                options={clientOptions}
                onChange={(e) => onClientSelect(e)}
                placeholder="Select Client..."
                styles={FilterDropDownStyle({ height: "40px" })}
              />
            </InputWithLabelSection>
          )}
        </TabSearchGrid>
        {selectedOrganization?.role !== "member" && (
          <ProjectRightButtonContainer>
            {/* <GrayButton>Export</GrayButton>
          <GrayButton>Print</GrayButton> */}
            <ColoredButtonWithIcon
              onClick={() => history.push("/user/create-project")}
            >
              <ButtonIcon src={Plus} alt="" />
              Create Project
            </ColoredButtonWithIcon>
          </ProjectRightButtonContainer>
        )}
      </HeaderContainer>
      {totalProjectCount && totalProjectCount > 0 && projectPageSize ? (
        <TopPagination
          itemName="projects"
          totalCount={totalProjectCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={projectPageSize}
        />
      ) : null}

      {isLoading ? (
        <Container>
          <ComponentCircleLoader />
        </Container>
      ) : (
        <ProjectListContainer>
          <ProjectsList
            selectedOrganization={selectedOrganization}
            isLoading={isLoading}
            searchTerm={searchTerm}
            selectedClient={selectedClient}
            projectsList={projectList}
            projectType={projectType}
            history={history}
            toggleArchiveModal={toggleArchiveModal}
            toggleDeleteModal={toggleDeleteModal}
            duplicateProject={duplicateProject}
            duplicateProjectIsLoading={duplicateProjectIsLoading}
            duplicateProjectId={duplicateProjectId}
            getProjectBudgetAndBillable={getProjectBudgetAndBillable}
            projectBudgetAndBillableIsLoading={
              projectBudgetAndBillableIsLoading
            }
            projectBudgetAndBillable={projectBudgetAndBillable}
            expandedProjectId={expandedProjectId}
            setExpandedProjectId={setExpandedProjectId}
          />
        </ProjectListContainer>
      )}
      {totalProjectCount && totalProjectCount > 0 && projectPageSize ? (
        <BottomPagination
          currentPage={currentPageNumber}
          totalCount={totalProjectCount}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={projectPageSize}
        />
      ) : null}
    </div>
  );
};

export default Projects;
