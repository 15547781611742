import { useState, useCallback, useEffect } from "react";

import { getCollapsedStates } from "../sidebarHelpers";

export const useCollapseState = (routes, location) => {
  // Helper function to find active collapse route
  const findActiveCollapse = useCallback(
    (pathname) => {
      return routes.find(
        (route) =>
          route.collapse &&
          route.views?.some(
            (view) =>
              `/user${view.path}` === pathname ||
              pathname.startsWith(`/user${view.path}/`)
          )
      );
    },
    [routes]
  );

  const [collapseState, setCollapseState] = useState(() => {
    const initialState = getCollapsedStates(routes);
    const activeCollapse = findActiveCollapse(location.pathname);

    if (activeCollapse) {
      return {
        ...initialState,
        [activeCollapse.state]: true,
      };
    }

    return initialState;
  });

  const toggleCollapse = useCallback((path) => {
    setCollapseState((prev) => {
      const newState = {};
      Object.keys(prev).forEach((key) => {
        newState[key] = false;
      });

      newState[path.state] = !prev[path.state];

      return newState;
    });
  }, []);

  useEffect(() => {
    const activeCollapse = findActiveCollapse(location.pathname);

    if (activeCollapse) {
      setCollapseState((prev) => {
        if (prev[activeCollapse.state]) return prev;

        const newState = {};
        Object.keys(prev).forEach((key) => {
          newState[key] = false;
        });

        newState[activeCollapse.state] = true;

        return newState;
      });
    }
  }, [location.pathname, findActiveCollapse]);

  return [collapseState, toggleCollapse];
};
