import { connect } from "react-redux";
import AsanaDetails from "./AsanaDetails";

// Actions
import {
  syncAsanaIntegration,
  deleteAsanaIntegration,
  updateAsanaIntegration,
  getIsActiveAsana
} from "../asanaIntegrationsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.asanaIntegrations.isLoading,
  syncIsLoading: state.asanaIntegrations.syncIsLoading,
  isActiveAsana: state.asanaIntegrations.isActiveAsana,
  isInstalledAsana: state.asanaIntegrations.isInstalledAsana,
  asanaInfo: state.asanaIntegrations.asanaInfo,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  syncAsanaIntegration: (payload) =>
    dispatch(syncAsanaIntegration(payload)),
  deleteAsanaIntegration: (payload) =>
    dispatch(deleteAsanaIntegration(payload)),
  updateAsanaIntegration: (payload) =>
    dispatch(updateAsanaIntegration(payload)),
  getIsActiveAsana: (payload) => 
    dispatch(getIsActiveAsana(payload))
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AsanaDetails);
