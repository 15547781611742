import { useState, useEffect, useRef } from "react";
import moment from "moment-timezone";

import {
  Container,
  TableText,
  CommonText,
  CommonGrid,
  ColoredText,
  CommonFlex,
  FormAlert,
} from "../../styledComponents/common";
import { Cross, CrossIcon } from "../../styledComponents/createProject";
import { TableItem } from "../../styledComponents/teams";
import {
  TasksTableRow,
  AddTaskContainer,
  AddTaskInputAndProjectContainer,
  AddTaskInput,
} from "./tasksStyles";
import { PrimaryButton, WhiteButton } from "../../styledComponents/buttons";
import {
  TaskTable,
  RoundComplete,
  CompletedDiv,
  CollapseTable,
  CompletedButton,
  CreateTaskContainer,
  CreateTaskInput,
  CreateTaskButtonContainer,
  TaskParentProjectName,
  NumberOfTasks,
  NoTaskDiv,
  NoTaskArtwork,
  NoTaskText,
  IntegrationIconDiv,
  IntegrationIcon,
} from "../../styledComponents/tasks";

import { formatDurationToHoursMinutes } from "../../utils/helper";

import { CommDropdownComp } from "./AddAssigneeDropdown";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import DropdownLoader from "../../components/DropdownLoader/DropdownLoader";
import TaskActionTooltip from "../../components/Tooltip/TaskActionTooltip";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";
import { AddTaskSection } from "./Tasks";

import plus from "../../assets/img/icons/plus.svg";
import tickWhite from "../../assets/img/icons/tick_2.svg";
import trelloIcon from "../../assets/img/integrations/trello.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";

const TasksList = (props) => {
  const [integrationTooltip, setIntegrationTooltip] = useState(null);

  const {
    selectedOrganization,
    taskListLoading,
    tasksList,
    projectAddTask,
    editTask,
    projectDeleteTask,
    getTaskList,
    getProjectAssigneeList,
    projectAssigneeList,
    addTaskAssignee,
    deleteTaskAssignee,
    searchTerm,
    projectAssigneeIsLoading,
    taskIsLoading,
    loadingTaskId,
    loadingTaskProjectId,
    taskAssigneeIsLoading,
    loadingTaskAssigneeId,
    newTask,
    handleNewTask,
    projectData,
    fromProject,
    taskStatus,
    visibilityChange,
    currentPageNumber,
  } = props;

  const changeStatus = (projectId, taskId, status) => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        task_id: taskId,
        status: status === "to do" ? "complete" : "to do",
        taskStatus,
        currentPageNumber,
      };
      editTask(payload);
    }
  };

  const handleDeleteTask = (projectId, taskId) => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        task_id: taskId,
        currentPageNumber,
      };
      projectDeleteTask(payload);
    }
  };

  const updateAssingeeList = (person, projectId, taskId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      projectId &&
      taskId &&
      person &&
      person.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        task_id: taskId,
        user_id: person.id,
      };
      addTaskAssignee(payload);
    }
  };

  const handleDeleteAssignee = (assigneeId, projectId, taskId) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      projectId &&
      taskId &&
      assigneeId
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        task_id: taskId,
        user_id: assigneeId,
      };
      deleteTaskAssignee(payload);
    }
  };

  return (
    <Container
      padding="0 0 20px"
      borderRadius={projectData && "none"}
      borderColor={projectData && "transparent"}
    >
      {taskListLoading ? (
        <ComponentCircleLoader />
      ) : tasksList && tasksList.length > 0 ? (
        <>
          <TasksTableRow header projectData={projectData}>
            <TableItem>
              <TableText>Task Name</TableText>
            </TableItem>
            {!projectData && (
              <TableItem>
                <TableText>Project</TableText>
              </TableItem>
            )}
            <TableItem>
              <TableText>Time Worked</TableText>
            </TableItem>
            <TableItem padding="10px 0 10px 10px">
              <TableText>Assignees</TableText>
            </TableItem>
            <TableItem>
              <TableText>Created Date</TableText>
            </TableItem>
          </TasksTableRow>
          {tasksList.map((task, index) => {
            if (taskIsLoading && loadingTaskId && loadingTaskId === task.id) {
              return (
                <div style={{ padding: "15px 30px" }} key={task.id}>
                  <DropdownLoader loading />
                </div>
              );
            } else {
              return (
                <TasksTableRow
                  key={task.id}
                  padding="0 30px 0 15px"
                  projectData={projectData}
                >
                  <TableItem
                    style={{
                      gridTemplateColumns: "auto auto auto",
                      justifyContent: "start",
                      alignItems: "center",
                      gridColumnGap: "10px",
                    }}
                  >
                    <RoundComplete
                      onClick={() =>
                        changeStatus(
                          task.project
                            ? task.project.id
                            : projectData
                            ? projectData.id
                            : null,
                          task.id,
                          task.status
                        )
                      }
                      complete={task.status === "complete" ? true : false}
                      style={{ position: "relative" }}
                    >
                      <TaskActionTooltip
                        toolTipText={
                          task.status === "to do"
                            ? "Complete Task"
                            : "Make To Do"
                        }
                      />
                      <img
                        src={tickWhite}
                        alt="tick"
                        width="13px"
                        height="13px"
                      />
                    </RoundComplete>
                    <TableText name title={task.name}>
                      {task.name}
                    </TableText>
                    {task.integrations &&
                      task.integrations.length > 0 &&
                      task.integrations.map((integration, index) => {
                        return (
                          <IntegrationIconDiv
                            key={index}
                            onMouseEnter={() => setIntegrationTooltip(task.id)}
                            onMouseLeave={() => setIntegrationTooltip(null)}
                          >
                            <IntegrationIcon
                              src={`https://cdn.apploye.com/integrations/icons/${integration}.svg`}
                              alt={integration}
                            />
                            {integrationTooltip &&
                              integrationTooltip === task.id && (
                                <CommonTooltip
                                  tooltipText={`${integration
                                    .charAt(0)
                                    .toUpperCase()}${integration.slice(1)}`}
                                  left="-32px"
                                  top="-30px"
                                  width="82px"
                                  height="26px"
                                />
                              )}
                          </IntegrationIconDiv>
                        );
                      })}
                  </TableItem>
                  {!projectData && (
                    <TableItem>
                      <TableText name title={task.project?.name}>
                        {task.project ? task.project.name : "-"}
                      </TableText>
                    </TableItem>
                  )}
                  <TableItem buttonPadding>
                    <WhiteButton
                      height="32px"
                      padding="0 8px"
                      borderColor="#20BEAD"
                      hoverBorderColor="#20bead"
                      color="#3d4d69"
                      cursor="auto"
                      style={{ maxWidth: `180px` }}
                      hoverBorder="#20bead"
                    >
                      {formatDurationToHoursMinutes(task.time_spent)}
                    </WhiteButton>
                  </TableItem>
                  <TableItem padding="10px 0 10px 10px">
                    <CommDropdownComp
                      numberOfVisibleMembers={4}
                      isLoading={projectAssigneeIsLoading}
                      selectedOrganization={selectedOrganization}
                      assigneeManage={task.members}
                      updateState={updateAssingeeList}
                      getMembersList={getProjectAssigneeList}
                      membersList={projectAssigneeList}
                      visibilityChange={
                        visibilityChange ? visibilityChange : () => true
                      }
                      assigneeRemove={handleDeleteAssignee}
                      taskId={task.id}
                      projectId={
                        task.project
                          ? task.project.id
                          : projectData
                          ? projectData.id
                          : null
                      }
                      assigneeIsLoading={taskAssigneeIsLoading}
                      loadingAssigneeId={loadingTaskAssigneeId}
                    />
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {selectedOrganization && selectedOrganization.timezone
                        ? moment
                            .tz(task.created_at, "America/Chicago")
                            .tz(selectedOrganization.timezone)
                            .format("D MMM, YYYY")
                        : moment(task.created_at).format("D MMM, YYYY")}
                    </TableText>
                  </TableItem>
                  <TableItem>
                    <Cross
                      onClick={() =>
                        handleDeleteTask(
                          task.project
                            ? task.project.id
                            : projectData
                            ? projectData.id
                            : null,
                          task.id
                        )
                      }
                    >
                      <CrossIcon src={crossIcon} alt="" />
                    </Cross>
                  </TableItem>
                </TasksTableRow>
              );
            }
          })}
          {newTask && newTask === "bottom" ? (
            <AddTaskSection
              fromProject={fromProject}
              projectData={projectData}
              selectedOrganization={selectedOrganization}
              handleNewTask={handleNewTask}
              projectAddTask={projectAddTask}
              currentPageNumber={currentPageNumber}
              position="bottom"
              bottomAddTaskMargin="6px"
            />
          ) : (
            <CommonFlex
              margin="20px 0"
              padding="0 30px"
              gap="8px"
              justifyContent="flex-start"
              alignItems="center"
              style={{ cursor: `pointer` }}
              onClick={() => handleNewTask("bottom")}
            >
              <img src={plus} alt="" width="20px" />
              <ColoredText fontSize="14px" primary semibold>
                Add Task
              </ColoredText>
            </CommonFlex>
          )}
        </>
      ) : (
        <>
          <NoDataComponent
            title="You do not have any task!"
            padding={fromProject && "5vh 0"}
            imageHeight={fromProject && "140px"}
          />
          {fromProject ? (
            newTask && newTask === "bottom" ? (
              <AddTaskSection
                fromProject={fromProject}
                projectData={projectData}
                selectedOrganization={selectedOrganization}
                handleNewTask={handleNewTask}
                projectAddTask={projectAddTask}
                currentPageNumber={currentPageNumber}
                noRadius
                position="bottom"
              />
            ) : (
              <CommonFlex
                margin="20px 0"
                padding="0 30px"
                gap="8px"
                justifyContent="flex-start"
                alignItems="center"
                style={{ cursor: `pointer` }}
                onClick={() => handleNewTask("bottom")}
              >
                <img src={plus} alt="" width="20px" />
                <ColoredText fontSize="14px" primary semibold>
                  Add Task
                </ColoredText>
              </CommonFlex>
            )
          ) : null}
        </>
      )}
    </Container>
  );
};

export default TasksList;
