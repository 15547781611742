import {
    GET_IS_INSTALLED_ASANA,
    GET_IS_ACTIVE_ASANA,
    CONNECT_ASANA,
    SYNC_ASANA,
    DELETE_ASANA,
    UPDATE_ASANA,
    UPDATE_ASANA_CREDENTIALS,
    INVITE_MEMBER_BULK,
    GET_ASANA_INFO,
    GET_ASANA_CREDENTIALS
  } from "../../../modules/constants";
  
  
  export function getAsanaInfo(payload) {
    return {
      type: GET_ASANA_INFO,
      payload,
    };
  }
  export function getAsanaCredentials(payload) {
    return {
      type: GET_ASANA_CREDENTIALS,
      payload,
    };
  }
  export function getIsInstalledAsana(payload) {
    return {
      type: GET_IS_INSTALLED_ASANA,
      payload,
    };
  }
  export function getIsActiveAsana(payload) {
    return {
      type: GET_IS_ACTIVE_ASANA,
      payload,
    };
  }
  
  export function connectAsanaIntegration(payload) {
    return {
      type: CONNECT_ASANA,
      payload,
    };
  }
  
  export function syncAsanaIntegration(payload) {
    return {
      type: SYNC_ASANA,
      payload,
    };
  }
  
  export function deleteAsanaIntegration(payload) {
    return {
      type: DELETE_ASANA,
      payload,
    };
  }
  
  export function updateAsanaIntegration(payload) {
    return {
      type: UPDATE_ASANA,
      payload,
    };
  }
  
  export function updateAsanaCredentials(payload) {
    return {
      type: UPDATE_ASANA_CREDENTIALS,
      payload,
    };
  }
  
  export function inviteMemberBulk(payload) {
    return {
      type: INVITE_MEMBER_BULK,
      payload,
    };
  }
  