import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import exportImg from "../../../assets/img/icons/export_icon.svg";
import {
  PageTitle,
  CommonGrid,
  CommonFlex,
} from "../../../styledComponents/common";
import { TimesheetContainer } from "../../../styledComponents/timesheet";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import TimesheetFilter from "../TimesheetFilter";
import WeeklyMemberView from "./WeeklyMemberView";
import WeeklyAdminView from "./WeeklyAdminView";

import RightSlidingPanel from "../../../components/RightSlidingPanel/RightSlidingPanel";
import TimesheetDetails from "../TimesheetDetailsPanel";

import Plus from "../../../assets/img/icons/plus_white.svg";
import {
  ColoredButton,
  ExportButtonIcon,
  ExportButtonWithIcon,
} from "../../../styledComponents/buttons";
import BackendExportModal from "../../../custom_modules/ReportsNew/BackendExportModal";
import { getFullName } from "../../../utils/helper";

const Weekly = ({
  selectedOrganization,
  profileData,
  clearTimesheetList,
  getWeeklyTimesheetDetails,
  getOtherTimesheets,
  getDailyTimesheets,
  isLoading,
  timesheetDetails,
  organizationMembersList,
  dailyTimesheetLoading,
  dailyTimesheets,
  timesheet,
  exportWeeklyTimeSheet,
  taskAssigneeList,
  projectAssigneeList,
  liveMembersList,
  getLiveMembersList,
}) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedTask, setSelectedTask] = useState("");
  const [projectAssignees, setProjectAssignees] = useState([]);
  const [taskAssignees, setTaskAssignees] = useState([]);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);

  const [timeFormat, setTimeFormat] = useState("1h5m");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [activeDate, setActiveDate] = useState(null);
  const [relevantDates, setRelevantDates] = useState([]);
  const [expandedAll, setExpandedAll] = useState(false);
  const [expandedTimesheets, setExpandedTimesheets] = useState([]);

  const [selectedPanelDate, setSelectedPanelDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );

  useEffect(() => {
    if (timesheetDetails && Object.keys(timesheetDetails).length > 0) {
      const newDates = Object.values(timesheetDetails)
        .flatMap((items) => items.map((item) => item.date)).sort();
  
      setRelevantDates(newDates);
    }
  }, [timesheetDetails]);
  
  const disabledNext = useMemo(() => {
    if (moment(selectedPanelDate).isSame(moment(endDate), "day")) {
      return true;
    }
  
    const hasNextRelevantDate = relevantDates.some(
      (date) => moment(date).isAfter(moment(selectedPanelDate))
    );
  
    return !hasNextRelevantDate;
  }, [selectedPanelDate, relevantDates, endDate]);
  
  const disabledPrev = useMemo(() => {
    if (moment(selectedPanelDate).isSame(moment(startDate), "day")) {
      return true;
    }
  
    const hasPreviousRelevantDate = relevantDates.some(
      (date) => moment(date).isBefore(moment(selectedPanelDate))
    );
  
    return !hasPreviousRelevantDate;
  }, [selectedPanelDate, relevantDates, startDate]);  

  const toggle = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedProject("");
      setSelectedTask("");
      setSelectedMember("");

      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };
      if (
        selectedOrganization?.role === "owner" ||
        selectedOrganization?.role === "admin" ||
        selectedOrganization?.role === "team_leader"
      ) {
        getWeeklyTimesheetDetails(payload);
      } else {
        getOtherTimesheets(payload);
      }
      setSelectedProject("");
      setSelectedProject("");
      setSelectedMember("");
      getLiveMembersList({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: getFullName(assignee.user),
          avatar: assignee.user.avatar ? assignee.user.avatar : null,
          color: assignee.user.color ? assignee.user.color : null,
        });
      });
      setProjectAssignees(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setProjectAssignees([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if (taskAssigneeList && taskAssigneeList.length > 0) {
      let assigneeList = [];
      taskAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.id,
          name: getFullName(assignee),
          avatar: assignee.avatar ? assignee.avatar : null,
          color: assignee.color ? assignee.color : null,
        });
      });
      setTaskAssignees(assigneeList);
    } else if (taskAssigneeList && taskAssigneeList.length === 0) {
      setTaskAssignees([]);
    }
  }, [taskAssigneeList]);

  const selectProject = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedOrganization?.role === "member" || selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      if (
        (selectedOrganization?.role === "owner" ||
          selectedOrganization?.role === "admin" ||
          selectedOrganization?.role === "team_leader") &&
        !selectedMember
      ) {
        getWeeklyTimesheetDetails(payload);
      } else {
        getOtherTimesheets(payload);
      }
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
      setSelectedTask("");
    } else if ((e && !e.value) || e == null) {
      setSelectedProject(e);
      setSelectedTask("");
      setSelectedMember("");
    } else {
      setSelectedProject(e);
    }
  };

  const selectTask = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (typeof selectedProject === "object" && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedOrganization?.role === "member" || selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.task_id = e.value;
      }
      if (
        (selectedOrganization?.role === "owner" ||
          selectedOrganization?.role === "admin" ||
          selectedOrganization?.role === "team_leader") &&
        !selectedMember
      ) {
        getWeeklyTimesheetDetails(payload);
      } else {
        getOtherTimesheets(payload);
      }
    }

    setSelectedTask(e);
  };

  const selectTeamMember = (e) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
        user_id: e.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      setSelectedMember(e);
      getOtherTimesheets(payload);
    }
  };

  const clearMember = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (
        selectedOrganization?.role === "owner" ||
        selectedOrganization?.role === "admin" ||
        selectedOrganization?.role === "team_leader"
      ) {
        getWeeklyTimesheetDetails(payload);
      } else {
        getOtherTimesheets(payload);
      }
    }
    setSelectedMember("");
  };

  const selectDate = (date) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    setStartDate(moment(date).startOf("isoWeek").format("YYYY-MM-DD"));
    setEndDate(moment(date).endOf("isoWeek").format("YYYY-MM-DD"));
    const payload = {
      organization_id: organizationId,
      start_date: moment(date).startOf("isoWeek").format("YYYY-MM-DD"),
      end_date: moment(date).endOf("isoWeek").format("YYYY-MM-DD"),
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== "") {
      payload.user_id = selectedMember.id;
    }
    if (
      (selectedOrganization?.role === "owner" ||
        selectedOrganization?.role === "admin" ||
        selectedOrganization?.role === "team_leader") &&
      !selectedMember
    ) {
      getWeeklyTimesheetDetails(payload);
    } else {
      getOtherTimesheets(payload);
    }
  };

  const selectPanelDate = (date, userId) => {
    setSelectedPanelDate(moment(date).format("YYYY-MM-DD"));
    const payload = {
      organization_id: selectedOrganization.id,
      date: date.format("YYYY-MM-DD"),
      user_id: userId,
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== "") {
      payload.user_id = selectedMember.id;
    }
    if (selectedOrganization.timezone) {
      payload.timezone = selectedOrganization.timezone;
    }
    getDailyTimesheets(payload);
  };

  const singlePanelDateChange = (direction, userId) => {
    setSelectedPanelDate((prevDate) => {
      const currentDate = moment(prevDate).format("YYYY-MM-DD");
      let newDate = null;
      if (direction === "right") {
        newDate = relevantDates.find((date) => date > currentDate) || 
                  moment(prevDate).add(1, "days").format("YYYY-MM-DD");
      } else {
        newDate = [...relevantDates]
          .reverse()
          .find((date) => date < currentDate) || 
          moment(prevDate).subtract(1, "days").format("YYYY-MM-DD");
      }
  
      selectPanelDate(moment(newDate), userId);
      return moment(newDate);
    });
  };  

  // useEffect(() => {
  //   if (
  //     moment(selectedPanelDate).isBefore(moment(startDate), "day") || 
  //     moment(selectedPanelDate).isAfter(moment(endDate), "day")
  //   ) {
  //     selectDate(selectedPanelDate);
  //   }
  // }, [selectedPanelDate]);

  const toggleDetailsPanel = (value) => {
    setDetailsPanelIsOpen(value);
    if (!value) {
      setDetailsUserData(null);
    }
  };

  const handleGetTimesheetDetails = (date, userId) => {
    if (selectedOrganization && selectedOrganization.id && date && userId) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format("YYYY-MM-DD"),
        user_id: userId,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedOrganization.timezone) {
        payload.timezone = selectedOrganization.timezone;
      }
      getDailyTimesheets(payload);
      toggleDetailsPanel(true);
    }
  };

  const handleExportTimesheetDetails = () => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : "",
      start_date: startDate,
      end_date: endDate,
      time_format: timeFormat,
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== "") {
      payload.user_id = selectedMember.id;
    }
    if (
      (selectedOrganization?.role === "owner" ||
        selectedOrganization?.role === "admin" ||
        selectedOrganization?.role === "team_leader") &&
      !selectedMember
    ) {
      exportWeeklyTimeSheet(payload);
    }
    toggle();
  };

  const changeDateRange = (e) => {
    setStartDate(moment(e.startDate).format("YYYY-MM-DD"));
    setEndDate(moment(e.endDate).format("YYYY-MM-DD"));
  };
  const selectLastWeek = () => {
    setStartDate(
      moment().subtract(6, "days").startOf("isoWeek").format("YYYY-MM-DD")
    );
    setEndDate(
      moment().subtract(6, "days").endOf("isoWeek").format("YYYY-MM-DD")
    );
  };
  const selectCurrentWeek = () => {
    setStartDate(moment().startOf("isoWeek").format("YYYY-MM-DD"));
    setEndDate(moment().endOf("isoWeek").format("YYYY-MM-DD"));
  };

  useEffect(() => {
    const payload = {
      organization_id: selectedOrganization ? selectedOrganization.id : "",
      start_date: startDate,
      end_date: endDate,
    };
    if (selectedProject && selectedProject.value) {
      payload.project_id = selectedProject.value;
    }
    if (selectedTask && selectedTask.value) {
      payload.task_id = selectedTask.value;
    }
    if (selectedMember && selectedMember.id !== "") {
      payload.user_id = selectedMember.id;
    }
    if (
      (selectedOrganization?.role === "owner" ||
        selectedOrganization?.role === "admin" ||
        selectedOrganization?.role === "team_leader") &&
      !selectedMember
    ) {
      getWeeklyTimesheetDetails(payload);
    } else {
      getOtherTimesheets(payload);
    }
  }, [startDate, endDate]);


  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toggleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35vh 0 " />
        ) : (
          <>
            <BackendExportModal
              isOpen={isExportModalOpen}
              toggle={toggle}
              excelExport={handleExportTimesheetDetails}
              title="Weekly Timesheet"
              timeFormat={timeFormat}
              setTimeFormat={setTimeFormat}
            />
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userData={detailsUserData}
              selectedProject={selectedProject}
              selectedTask={selectedTask}
              fromPage={
                selectedOrganization &&
                (selectedOrganization?.role === "admin" ||
                  selectedOrganization?.role === "owner" ||
                  selectedOrganization?.role === "team_leader") &&
                !selectedMember
                  ? "weeklyAdmin"
                  : "other"
              }
              timezone={selectedOrganization && selectedOrganization.timezone}
              expandedAll={expandedAll}
              setExpandedAll={setExpandedAll}
              expandedTimesheets={expandedTimesheets}
              setExpandedTimesheets={setExpandedTimesheets}
              selectedPanelDate={moment(selectedPanelDate)}
              singlePanelDateChange={singlePanelDateChange}
              onPanelDateChange={selectPanelDate}
              disabledNext={disabledNext}
              disabledPrev={disabledPrev}
              setDetailsPanelIsOpen={setDetailsPanelIsOpen}
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Weekly Timesheet</PageTitle>
      </CommonGrid>
      <CommonFlex justifyContent="space-between" alignItems="end">
        <TimesheetFilter
          page="weekly"
          selectedDate={moment(startDate)}
          startDate={startDate}
          endDate={endDate}
          onDateChange={selectDate}
          selectedProject={selectedProject}
          selectProject={selectProject}
          selectedTask={selectedTask}
          selectTask={selectTask}
          selectedMember={selectedMember}
          selectMember={selectTeamMember}
          clearMember={clearMember}
          changeDateRange={changeDateRange}
          selectCurrentWeek={selectCurrentWeek}
          selectLastWeek={selectLastWeek}
        />
        {(selectedOrganization?.role === "admin" ||
          selectedOrganization?.role === "owner" ||
          selectedOrganization?.role === "team_leader") &&
          !selectedMember && (
            <ExportButtonWithIcon onClick={() => toggle()}>
              <ExportButtonIcon src={exportImg} alt="" />
              Export
            </ExportButtonWithIcon>
          )}
      </CommonFlex>
      <TimesheetContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : selectedOrganization &&
          (selectedOrganization?.role === "admin" ||
            selectedOrganization?.role === "owner" ||
            selectedOrganization?.role === "team_leader") &&
          !selectedMember ? (
          <WeeklyAdminView
            selectedDate={startDate}
            timesheetsList={timesheetDetails}
            membersList={
              selectedTask && selectedTask.value
                ? taskAssignees
                : selectedProject && selectedProject.value
                ? projectAssignees
                : organizationMembersList
            }
            liveMembersList={liveMembersList}
            getTimesheetDetails={handleGetTimesheetDetails}
            detailsUserData={detailsUserData}
            setDetailsUserData={setDetailsUserData}
            selectedPanelDate={selectedPanelDate}
            setSelectedPanelDate={setSelectedPanelDate}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
            isDetailsOpen={detailsPanelIsOpen}
          />
        ) : selectedOrganization ? (
          <WeeklyMemberView
            profileData={profileData}
            selectedDate={startDate}
            selectedMember={selectedMember}
            timesheetsList={timesheet}
            getTimesheetDetails={handleGetTimesheetDetails}
            detailsUserData={detailsUserData}
            setDetailsUserData={setDetailsUserData}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
            selectedPanelDate={selectedPanelDate}
            setSelectedPanelDate={setSelectedPanelDate}
            isDetailsOpen={detailsPanelIsOpen}
          />
        ) : null}
      </TimesheetContainer>
    </div>
  );
};

export default Weekly;
