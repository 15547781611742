import { useState, useEffect } from "react";
import moment from "moment";
import UrlLists from "./UrlLists";
import FirstRoundLetter from "../../../components/ColorLetterRound/FirstRoundLetter";
import { formatDurationToHoursMinutes } from "../../../utils/helper";

import {
  CommonGrid,
  Container,
  CommonText,
} from "../../../styledComponents/common";
import { ActivityTextSection } from "../../../styledComponents/members";
import {
  AppsTimeSection,
  AppTimeItem,
  AppUrlCard,
} from "../../../styledComponents/Activity";
import { ClientCardsContainer } from "../../../styledComponents/clients";

const UrlSection = ({ urlSection }) => {
  const [timeDuration, setTimeDuration] = useState(0);

  const getUrlData = (urlList) => {
    UrlLists.map((item) => {
      urlList.map((url) => {
        if (trimUrl(url.url) === item.name) {
          url.name = item.name;
          url.icon = item.icon;
        }
      });
    });
    return urlList.sort((a, b) => {
      const aName = a.name ? a.name?.toLowerCase() : a.url?.toLowerCase();
      const bName = b.name ? b.name?.toLowerCase() : b.url?.toLowerCase();
      return aName.localeCompare(bName);
    });
  };

  const trimUrl = (url) => {
    const protocolPattern = /^[a-zA-Z]+:\/\//;
    url = url.replace(protocolPattern, "");

    // Extract the hostname by splitting at the first occurrence of /, ?, or #
    return url.split(/[/?#]/)[0];
  };
  // const urlCheck = (value) => {
  //   if (value == "http://localhost:3000" || value == "localhost:3000")
  //     return false;
  //   try {
  //     var request;
  //     if (window.XMLHttpRequest) request = new XMLHttpRequest();
  //     else request = new ActiveXObject("Microsoft.XMLHTTP");
  //     request.open("GET", value, false);
  //     request.send();
  //     if (request.status === 404) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } catch (ex) {
  //     //  alert("got");
  //     return false;
  //   }
  // };

  useEffect(() => {
    if (urlSection.duration) {
      setTimeDuration(formatDurationToHoursMinutes(urlSection.duration));
    }
  }, [urlSection]);

  return (
    <Container padding="30px" margin="20px 0 0 0">
      <CommonGrid>
        <ActivityTextSection gap="5px" style={{ alignContent: `start` }}>
          <CommonText name fontSize="16px" title>
            {urlSection.project ? urlSection.project.name : "No project"}
          </CommonText>
          <CommonText>
            {(urlSection.task && urlSection.task.name) || "No task"}
          </CommonText>
        </ActivityTextSection>
        <AppsTimeSection>
          <AppTimeItem index={1}>
            <CommonText name title>
              {urlSection.start_timestamp.format("h:mm A")}
            </CommonText>
            <CommonText $label fontWeight="500">
              Start Time
            </CommonText>
          </AppTimeItem>
          <AppTimeItem index={2}>
            <CommonText name title>
              {urlSection.end_timestamp.format("h:mm A")}
            </CommonText>
            <CommonText $label fontWeight="500">
              End Time
            </CommonText>
          </AppTimeItem>
          <AppTimeItem index={3}>
            <CommonText name title>
              {timeDuration}
            </CommonText>
            <CommonText $label fontWeight="500">
              Total Time
            </CommonText>
          </AppTimeItem>
        </AppsTimeSection>
      </CommonGrid>
      <ClientCardsContainer style={{ gridGap: `10px` }}>
        {urlSection.urls.length > 0 &&
          getUrlData(urlSection.urls).map((url, index) => (
            <AppUrlCard key={index}>
              {/* {urlCheck(`${trimUrl(url.url)}`) ? (
                <div>
                  <img
                    src={`https://www.google.com/s2/favicons?sz=64&domain_url=${trimUrl(
                      url.url
                    )}`}
                    width="100%"
                    alt="item.name"
                  />
                </div>
              ) : ( */}
              <FirstRoundLetter
                text={trimUrl(url.url)}
                backColor={index}
                size="42px"
                fontSize="20px"
              />
              {/* )} */}
              <ActivityTextSection gap="5px">
                <CommonText
                  name
                  style={{
                    wordWrap: "break-word",
                    hyphens: "auto",
                  }}
                >
                  {url.name ? url.name : trimUrl(url.url)}
                </CommonText>
                <CommonText fontSize="13px">
                  {formatDurationToHoursMinutes(url.time_spent)}
                </CommonText>
              </ActivityTextSection>
            </AppUrlCard>
          ))}
      </ClientCardsContainer>
    </Container>
  );
};

export default UrlSection;
