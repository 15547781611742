import { useState, useEffect } from "react";
import { toHHMMSS, getHours, getFullName } from "../../../utils/helper";
import moment from "moment";
import {
  TotalTimeContainer,
  TimeRangeText,
  TableContainer,
  TableHeadContainer,
  TableHeadItem,
  TableBodyContainer,
  TableBodyItem,
  DailyTime,
  TimesheetDate,
  WeeklyTotal,
  DailyTimeWithSheet,
} from "../../../styledComponents/timesheet";

import { getRandomColor } from "utils/getRandomColor";

const getTimes = (date) => {
  const times = [];
  let startDate = moment(date).startOf("isoWeek");
  while (moment(startDate).isSameOrBefore(moment(date).endOf("isoWeek"))) {
    times.push({
      date: startDate,
      hours: "0 h 0 m",
    });
    startDate = moment(startDate).add(1, "days");
  }
  return times;
};

const WeeklyMemberView = ({
  selectedDate,
  selectedMember,
  profileData,

  timesheetsList,
  getTimesheetDetails,
  detailsUserData,
  setDetailsUserData,
  activeDate,
  setActiveDate,
  selectedPanelDate,
  setSelectedPanelDate,
  isDetailsOpen,
}) => {
  const [timesheetsData, setTimesheetsData] = useState(null);

  const [bgColors, setBgColors] = useState({});

  useEffect(() => {
    const times = getTimes(selectedDate);
    const durations = [];
    if (timesheetsList && timesheetsList.length > 0) {
      timesheetsList.forEach((timesheet) => {
        durations.push(timesheet.duration);
        times.some((item) => {
          if (moment(item.date).format("YYYY-MM-DD") === timesheet.date) {
            item.hours = getHours(toHHMMSS(timesheet.duration));
            return true;
          }
          return false;
        });
        if (
          detailsUserData &&
          detailsUserData.id === timesheet.user_id &&
          detailsUserData.date &&
          moment(detailsUserData.date).format("YYYY-MM-DD") === timesheet.date
        ) {
          setDetailsUserData({
            ...detailsUserData,
            totalTime: getHours(toHHMMSS(timesheet.duration)),
          });
        }
      });
      setTimesheetsData({
        durations,
        timesheets: [...times],
      });
    } else if (timesheetsList && timesheetsList.length === 0) {
      setTimesheetsData({
        durations,
        timesheets: times,
      });
    }
  }, [timesheetsList]);

  useEffect(() => {
    setActiveDate(selectedPanelDate);
  }, [selectedPanelDate]);

  return (
    <>
      <TotalTimeContainer>
        <TimeRangeText>{`${moment(selectedDate)
          .startOf("isoWeek")
          .format("MMMM DD")} - ${moment(selectedDate)
          .endOf("isoWeek")
          .format("MMMM DD")}`}</TimeRangeText>
      </TotalTimeContainer>
      <TableContainer>
        <TableHeadContainer>
          {timesheetsData &&
            timesheetsData.timesheets &&
            timesheetsData.timesheets.length > 0 &&
            timesheetsData.timesheets.map((time, index) => (
              <TableHeadItem key={index}>
                {moment(time.date).format("ddd")}
              </TableHeadItem>
            ))}
          <TableHeadItem total>Total</TableHeadItem>
        </TableHeadContainer>
        <TableBodyContainer>
          {timesheetsData &&
            timesheetsData.timesheets &&
            timesheetsData.timesheets.length > 0 &&
            timesheetsData.timesheets.map((time, index) => {
              if (!bgColors[time.date]) bgColors[time.date] = getRandomColor();
              return (
                <TableBodyItem key={index}>
                  {time.hours === "0 h 0 m" || time.hours === "0 h 0 m" ? (
                    <DailyTime 
                      backgroundColor="none"
                      isActive={activeDate===(moment((time.date)).format('YYYY-MM-DD')) && isDetailsOpen}
                    >-</DailyTime>
                  ) : (
                    <DailyTimeWithSheet
                      backgroundColor={bgColors[time.date]}
                      onClick={() => {
                        getTimesheetDetails(
                          time.date,
                          selectedMember
                            ? selectedMember.id
                            : localStorage.getItem("user_id")
                        );
                        setDetailsUserData({
                          id:
                            selectedMember?.id ??
                            localStorage.getItem("user_id"),
                          name:
                            selectedMember?.name ??
                            (profileData?.id ? getFullName(profileData) : null),
                          avatar: selectedMember
                            ? selectedMember.avatar ?? null
                            : profileData?.thumbnail ?? null,
                          color: selectedMember
                            ? selectedMember.color ?? null
                            : profileData?.color ?? null,
                          date: time.date,
                          totalTime: time.hours,
                        });
                        setSelectedPanelDate(moment((time.date)).format('YYYY-MM-DD'));
                      }}
                      isActive={activeDate===(moment((time.date)).format('YYYY-MM-DD')) && isDetailsOpen}
                      largerZ={isDetailsOpen}
                    >
                      {time.hours}
                    </DailyTimeWithSheet>
                  )}
                  <TimesheetDate>
                    {moment(time.date).format("MMM DD")}
                  </TimesheetDate>
                </TableBodyItem>
              );
            })}
          <TableBodyItem>
            <WeeklyTotal>
              {timesheetsData &&
              timesheetsData.durations &&
              timesheetsData.durations.length > 0
                ? getHours(
                    toHHMMSS(
                      timesheetsData.durations
                        .slice(1)
                        .reduce(
                          (prev, cur) => cur + prev,
                          timesheetsData.durations[0]
                        )
                    )
                  )
                : "0 h 0 m"}
            </WeeklyTotal>
          </TableBodyItem>
        </TableBodyContainer>
      </TableContainer>
    </>
  );
};

export default WeeklyMemberView;
