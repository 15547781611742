import { useDebouncedCallback } from "use-debounce";

import {
  OnboardingArtworkDiv,
  OnboardingArtwork,
  NextButtonIcon,
} from "../onboardingStepsStyles";
import {
  PendingInvitationContainer,
  PendingInvitationContent,
  PendingInvitationTitle,
  PendingInvitationSubtitle,
  PendingInvitationDescription,
  AcceptInvitationButton,
  DeclineInvitationLink,
  SignOutLink,
} from "./pendingInvitationStyles";
import { CommonFlex, CommonText } from "../../../../styledComponents/common";

import { createNotification } from "../../../../modules/notificationManager";

import artwork from "../../../../assets/img/onboardingImg/background-artwork.png";
import nextIcon from "../../../../assets/img/onboardingImg/right_arrow_green.svg";
import { clearLocalStorage } from "../../../../utils/handleLogout";

const PendingInvitation = ({
  history,
  pendingInvitation,
  setPendingInvitation,
  loginProfileCreatedReset,
}) => {
  const userFullName = localStorage.getItem("user_fullName");

  const handleAcceptInvitation = useDebouncedCallback(
    () => {
      if (!pendingInvitation.key) {
        createNotification(
          "error",
          "The invitation link is broken. Please contact your organization administrator for a new invitation link."
        );
        return;
      }

      history.push({
        pathname: "/accept-invitation",
        search: new URLSearchParams({
          key: pendingInvitation.key,
        }).toString(),
      });
    },
    3000,
    { leading: true, trailing: false }
  );

  const handleDeclineInvitation = () => {
    setPendingInvitation(null);
  };

  const handleSignOut = () => {
    clearLocalStorage();
    history.push("/auth/login");
    loginProfileCreatedReset();
  };

  return (
    <div>
      <PendingInvitationContainer>
        <PendingInvitationContent>
          <PendingInvitationTitle>
            Welcome, {userFullName}!
          </PendingInvitationTitle>
          <CommonFlex direction="column" gap="10px">
            <PendingInvitationSubtitle>
              <CommonText name fontSize="18px">
                We found that you've already been invited to join
              </CommonText>
              <CommonText
                name
                fontSize="18px"
                fontWeight="600"
                margin="10px 0 0"
              >
                {pendingInvitation?.organization_name}
              </CommonText>
            </PendingInvitationSubtitle>
            <PendingInvitationDescription>
              By joining, you'll be able to collaborate with your team, track
              time,<br /> and easily manage your work.
            </PendingInvitationDescription>
          </CommonFlex>
          <AcceptInvitationButton onClick={handleAcceptInvitation}>
            Accept Invitation
            <NextButtonIcon src={nextIcon} alt="" />
          </AcceptInvitationButton>
          <SignOutLink onClick={handleSignOut}>Sign Out</SignOutLink>
        </PendingInvitationContent>
        <DeclineInvitationLink onClick={handleDeclineInvitation}>
          Decline Invitation
        </DeclineInvitationLink>
      </PendingInvitationContainer>
      <OnboardingArtworkDiv>
        <OnboardingArtwork src={artwork} alt="" />
      </OnboardingArtworkDiv>
    </div>
  );
};

export default PendingInvitation;
