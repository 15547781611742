import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import DatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import {
  PageTitle,
  CommonFlex,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
  Container,
  CardTitle,
  CommonText,
  CommonGrid,
} from "../../../styledComponents/common";
import {
  ButtonIcon,
  TabButtonContainer,
  TabButton,
  PrimaryButton,
  ColoredButtonWithIcon,
  RefreshButton,
  FilterButton,
} from "../../../styledComponents/buttons";
import {
  DotContainer,
  DotCircle,
  InfoDiv,
} from "../CurrentlyTracking/currentlyTrackingStyles";
import { TabSearchGrid } from "../../../styledComponents/ProjectsNew";

import ScreenshotsTable from "./ScreenshotsTable";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";

import refreshIcon from "../../../assets/img/icons/refresh_white.svg";
import { getDateLabel } from "../../../utils/helper";
import { IconArrow } from "../../../styledComponents/Activity";
import LeftArrow_3 from "../../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../../assets/img/icons/RightArrow_3.svg";

const InstantScreenshots = (props) => {
  const [screenshotsList, setScreenshotsList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment());

  const {
    selectedOrganization,
    history,
    getInstantScreenshotsList,
    instantScreenshotsList,
    isLoading,
    buttonLoading,
  } = props;
  // Memoized values
  const isToday = useMemo(
    () => moment(selectedDate).isSame(moment(), "day"),
    [selectedDate]
  );
  const selectToday = () => {
    const date = moment();
    onDateSelect(date);
  };
  const singleDateChange = (direction) => {
    setSelectedDate((prevDate) => {
      const newDate =
        direction === "right"
          ? moment(prevDate).add(1, "days")
          : moment(prevDate).subtract(1, "days");
      onDateSelect(newDate);
      return newDate;
    });
  };
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = { organization_id: selectedOrganization.id };
      getInstantScreenshotsList(payload);
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  //   if (liveMembersList && liveMembersList.length > 0) {
  //     setMembersList(liveMembersList);
  //   } else if (liveMembersList && liveMembersList.length === 0) {
  //     setMembersList([]);
  //   }
  // }, [liveMembersList]);

  const onDateSelect = (date) => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedDate(date);
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format("YYYY-MM-DD"),
      };
      getInstantScreenshotsList(payload);
    }
  };

  const handleGetScreenshotsList = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = { organization_id: selectedOrganization.id };
      if (selectedDate) {
        payload.date = selectedDate.format("YYYY-MM-DD");
      }
      getInstantScreenshotsList(payload);
    }
  };

  return (
    <div className="content">
      <CommonFlex gap="5px" margin="0">
        <PageTitle margin="0">Instant Screenshots</PageTitle>
      </CommonFlex>
      {/* <InfoDiv>
        <CommonText name margin="0" fontSize="13px">
          Instant screenshot feature is only available for the members who are
          using the latest version of our desktop app (version: 3.1.6 or later).
        </CommonText>
        <CommonText margin="15px 0 0 0" fontSize="12px">
          Let all your members use the latest version of your desktop app to see
          their instant screenshots.
        </CommonText>
      </InfoDiv> */}
      <HeaderContainer justifyContent="space-between" margin="20px 0">
        <CommonGrid alignItem="flex-end" gap="50px">
          <BackButtonComponent
            onClick={() => history.push("/user/live-feed")}
            subTitle="Back to live feed"
          />
          <InputWithLabelSection>
            <FilterLabel>
              Date
              {getDateLabel({
                startDate: selectedDate,
                endDate: selectedDate,
                onlyToday: true,
              })}
            </FilterLabel>
            <CommonFlex gap="2px">
              <DatePicker
                id="instantScreenshotDate"
                date={selectedDate}
                onDateChange={onDateSelect}
                dateDisplayFormat="DD/MM/YYYY"
                placeholder="Select date..."
                allowPreviousDates
              />{" "}
              <CommonFlex gap="0px">
                <IconArrow
                  onClick={() => {
                    singleDateChange("left");
                  }}
                  padding="5px"
                  size="40px"
                  src={LeftArrow_3}
                />
                <IconArrow
                  margin="0 0 0 2px"
                  onClick={() => {
                    if (!isToday) {
                      singleDateChange("right");
                    }
                  }}
                  padding="5px"
                  size="40px"
                  src={RightArrow_3}
                  disabled={isToday}
                />
              </CommonFlex>
              <FilterButton
                type="gray"
                padding="10px"
                onClick={() => selectToday()}
              >
                Today
              </FilterButton>
            </CommonFlex>
          </InputWithLabelSection>
        </CommonGrid>

        <RefreshButton
          onClick={() => handleGetScreenshotsList()}
          isLoading={buttonLoading}
          padding="0 20px"
        >
          <ButtonIcon src={refreshIcon} alt="" size="22px" />
          Refresh
        </RefreshButton>
      </HeaderContainer>
      <ScreenshotsTable
        screenshotsList={instantScreenshotsList}
        isLoading={isLoading}
        selectedOrganization={selectedOrganization}
      />
    </div>
  );
};

export default InstantScreenshots;
