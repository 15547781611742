import { useState, useEffect } from "react";
import queryString from "query-string";

import PaginatedDropdown from "../ReactSelectPaginatedDropdown";

import FilterDropDownStyle from "../DropdownStyle/FilterDropDownStyle";

import { defaultApi } from "../../utils/axiosApi";

const getProjectOptions = async (
  search,
  prevOptions,
  page,
  pageSize,
  url,
  addAllProjects
) => {
  try {
    if (url) {
      const queryParams = { page };
      if (search.trim()) {
        queryParams.name = search;
      }
      const finalUrl = queryString.stringifyUrl({ url, query: queryParams });

      const response = await defaultApi(finalUrl, "GET");
      const { status, data } = response;

      const options =
        addAllProjects && page === 1
          ? [
              {
                value: null,
                label: "(All Projects)",
              },
            ]
          : [];
      let hasMore = false;

      if (status === 200 && data && data.results && data.results.length > 0) {
        data.results.forEach((item) => {
          options.push({
            value: item.id,
            label: item.name,
          });
        });
        if (data.next) {
          hasMore = true;
        }
      }
      return { options, hasMore };
    }
  } catch (error) {
    // console.log(error);
    return {
      options: [],
      hasMore: false,
    };
  }
};

const ProjectDropdown = (props) => {
  const [requestUrl, setRequestUrl] = useState("");
  const {
    selectedOrganization,
    selectedProject,
    selectProject,
    selectedMember,
    selectedClient,
    placeholder,
    style,
    indicatorIconSize,
    menuPlacement,
    addAllProjects = false,

    clearable,
    multiple,
  } = props;

  useEffect(() => {
    if (selectedOrganization?.id) {
      const url = `${selectedOrganization.id}/projects/short/?active=true`;
      if (selectedMember?.id) {
        setRequestUrl(
          queryString.stringifyUrl({
            url,
            query: { user: selectedMember.id },
          })
        );
      } else {
        setRequestUrl(url);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (requestUrl) {
      if (selectedMember?.id) {
        setRequestUrl(
          queryString.stringifyUrl({
            url: requestUrl,
            query: { user: selectedMember.id },
          })
        );
      } else {
        const parsedUrl = queryString.parseUrl(requestUrl);
        setRequestUrl(`${parsedUrl.url}?active=true`);
      }
    }
  }, [selectedMember]);

  useEffect (() => {
    const url = `${selectedOrganization?.id}/projects/short/?active=true`;
      if (selectedClient?.value) {
        setRequestUrl (
          queryString.stringifyUrl({
            url: url,
            query: { client: selectedClient?.value },
          })
        )
      }
  }, [selectedClient])
// console.log(requestUrl)



  return (
    <PaginatedDropdown
      isSearchable
      isClearable={clearable}
      isMulti={multiple}
      isDisabled={selectedClient === -1 }
      menuPlacement={menuPlacement}
      indicatorIconSize={indicatorIconSize}
      placeholder={placeholder || "Select Project"}
      value={selectedProject}
      url={requestUrl}
      getOptions={(search, prevOptions, page, pageSize, url) =>
        getProjectOptions(
          search,
          prevOptions,
          page,
          pageSize,
          url,
          addAllProjects
        )
      }
      onChange={(e) => selectProject(e)}
      styles={style || FilterDropDownStyle({ height: "40px" })}
    />
  );
};

export default ProjectDropdown;
