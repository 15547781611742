import {
  GET_TEAM_LIST,
  GET_TEAM_LIST_SUCCESS,
  GET_TEAM_LIST_FAILURE,
  GET_TEAM_SHORT_LIST,
  GET_TEAM_SHORT_LIST_SUCCESS,
  GET_TEAM_SHORT_LIST_FAILURE,
  GET_TEAM_DETAILS,
  GET_TEAM_DETAILS_SUCCESS,
  GET_TEAM_DETAILS_FAILURE,
  ADD_TEAM_REQUEST,
  ADD_TEAM_REQUEST_SUCCESS,
  ADD_TEAM_REQUEST_FAILURE,
  CLEAR_CREATED_TEAM_ID,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_REQUEST_SUCCESS,
  UPDATE_TEAM_REQUEST_FAILURE,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_REQUEST_SUCCESS,
  DELETE_TEAM_REQUEST_FAILURE,
  GET_TEAM_MEMBERS_LIST,
  GET_TEAM_MEMBERS_LIST_SUCCESS,
  GET_TEAM_MEMBERS_LIST_FAILURE,
  CLEAR_TEAM_MEMBERS_LIST,
  ADD_TEAM_MEMBER,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILURE,
  UPDATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,
  REMOVE_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_FAILURE,
  GET_TEAM_PROJECTS_LIST,
  GET_TEAM_PROJECTS_LIST_SUCCESS,
  GET_TEAM_PROJECTS_LIST_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,

  //from project
  ADD_TEAM_TO_PROJECT_SUCCESS,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const teamsListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const teamsListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  const list = data?.data?.results || [];

  // Sort by team name alphabetically
  const sortedList = list.sort((a, b) => a.name?.localeCompare(b.name));

  return {
    ...state,
    isLoading: false,
    totalTeamsCount: data?.data?.count || null,
    teamPageSize: data?.data?.page_size || null,
    teamsList: sortedList,
  };
};
export const teamsListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    totalTeamsCount: null,
    teamPageSize: null,
    teamsList: [],
  };
};

export const teamsShortListRequestHandler = (state, action) => {
  return {
    ...state,
    shortListIsLoading: true,
  };
};
export const teamsShortListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    shortListIsLoading: false,
    teamsShortList: data.data,
  };
};
export const teamsShortListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    shortListIsLoading: false,
    teamsShortList: [],
  };
};

export const teamDetailsRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const teamDetailsRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    teamDetails: data.data,
  };
};
export const teamDetailsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    teamDetails: null,
  };
};

export const addTeamRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const addTeamRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 201) {
    createNotification("success", "Team created.", 2000);

    return {
      ...state,
      isLoading: false,
      createdTeamId: data.data.id,
    };
  }
  return {
    ...state,
    isLoading: false,
    createdTeamId: null,
  };
};
export const addTeamRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong", 3000);
    return {
      ...state,
      isLoading: false,
      createdTeamId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    isLoading: false,
    createdTeamId: null,
  };
};

export const clearCreatedTeamId = (state, action) => {
  return {
    ...state,
    createdTeamId: null,
  };
};

export const updateTeamRequestHandler = (state, action) => {
  return {
    ...state,
    updateTeamLoading: true,
  };
};
export const updateTeamRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    createNotification("success", "Team info updated.", 2000);
    return {
      ...state,
      updateTeamLoading: false,
      teamDetails: data.data,
    };
  }
  return {
    ...state,
    updateTeamLoading: false,
  };
};
export const updateTeamRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification(
      "error",
      "Could not update team. Please try again!",
      3000
    );
    return {
      ...state,
      updateTeamLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    updateTeamLoading: false,
  };
};

export const deleteTeamRequestHandler = (state, action) => {
  return {
    ...state,
    deleteTeamLoading: true,
  };
};
export const deleteTeamRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, team_id, currentPageNumber },
  } = action;
  if (data && data.status === 204) {
    const lastPage = Math.ceil(state.totalTeamsCount / state.teamPageSize);
    createNotification("success", "Team deleted.", 2000);
    return {
      ...state,
      totalTeamsCount: state.totalTeamsCount - 1,
      reloadTeams:
        lastPage === currentPageNumber ? state.reloadTeams : !state.reloadTeams,
      deleteTeamLoading: false,
      teamsList: state.teamsList.filter((team) => team.id !== team_id),
    };
  }
  return {
    ...state,
    deleteTeamLoading: false,
  };
};
export const deleteTeamRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong", 3000);
    return {
      ...state,
      deleteTeamLoading: false,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    deleteTeamLoading: false,
  };
};

export const teamMembersListRequestHandler = (state, action) => {
  return {
    ...state,
    memberListLoading: true,
  };
};
export const teamMembersListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      memberListLoading: false,
      teamMembersCount: data.data.count,
      teamMembersPageSize: data.data.page_size,
      teamMembersList: data.data.results,
    };
  }
  return {
    ...state,
    memberListLoading: false,
    teamMembersCount: null,
    teamMembersPageSize: null,
    teamMembersList: [],
  };
};
export const teamMembersListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    memberListLoading: false,
    teamMembersCount: null,
    teamMembersPageSize: null,
    teamMembersList: [],
  };
};

export const clearTeamMembersListRequestHandler = (state, action) => {
  return {
    ...state,
    teamMembersCount: null,
    teamMembersList: [],
  };
};

export const addTeamMemberRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    teamMemberIsLoading: true,
    loadingMemberId: payload.user_id,
  };
};
export const addTeamMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    isFromMemberDetailsPage,
  } = action;
  if (data && data.status === 201) {
    if (isFromMemberDetailsPage) {
      createNotification("success", "Added to team.", 2000);
    }
    if (state.teamDetails) {
      const details = { ...state.teamDetails };
      details.members = [...details.members, data.data];
      createNotification("success", "Member added.", 2000);
      return {
        ...state,
        teamMemberIsLoading: false,
        loadingMemberId: null,
        teamMembersList: [...state.teamMembersList, data.data],
        teamDetails: details,
      };
    }
    return {
      ...state,
      teamMemberIsLoading: false,
      loadingMemberId: null,
      teamMembersList: [...state.teamMembersList, data.data],
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const addTeamMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong", 3000);
    return {
      ...state,
      teamMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    teamMemberIsLoading: false,
    loadingMemberId: null,
  };
};

export const updateTeamMemberRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    teamMemberIsLoading: true,
    loadingMemberId: payload.member_id,
  };
};
export const updateTeamMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    const membersList = JSON.parse(JSON.stringify(state.teamMembersList));
    membersList.forEach((member) => {
      // if (data.data.can_manage && member.can_manage) {
      //   member.can_manage = false;
      // }
      if (member.id === data.data.id) {
        member.can_manage = data.data.can_manage;
      }
    });

    if (data.data.can_manage) {
      createNotification("success", "Manager added.", 2000);
    } else {
      createNotification("success", "Manager removed.", 2000);
    }

    return {
      ...state,
      teamMemberIsLoading: false,
      loadingMemberId: null,
      teamMembersList: [...membersList],
    };
  }
  return {
    ...state,
    teamMemberIsLoading: false,
    loadingMemberId: null,
  };
};
export const updateTeamMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong", 3000);
    return {
      ...state,
      teamMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    teamMemberIsLoading: false,
    loadingMemberId: null,
  };
};

export const removeTeamMemberRequestHandler = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    teamMemberIsLoading: true,
    loadingMemberId: payload.user_id,
  };
};
export const removeTeamMemberRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
    userData,
    isFromMemberDetailsPage,
  } = action;
  if (data?.status == 204 && userData?.userId) {
    if (isFromMemberDetailsPage) {
      createNotification("success", "Removed from team.", 2000);
    }
    if (state.teamDetails) {
      const details = { ...state.teamDetails };
      const filteredList = details.members.filter(
        (member) => member.id !== userData.userId
      );
      details.members = [...filteredList];
      createNotification("success", "Member removed.", 2000);
      return {
        ...state,
        teamMembersList: state.teamMembersList.filter(
          (member) => member.id !== userData.userId
        ),
        teamDetails: details,
        teamMemberIsLoading: false,
        loadingMemberId: null,
      };
    }
    return {
      ...state,
      teamMembersList: state.teamMembersList.filter(
        (member) => member.id !== userData.userId
      ),
      teamMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  return {
    ...state,
    teamMemberIsLoading: false,
    loadingMemberId: null,
  };
};
export const removeTeamMemberRequestFailureHandler = (state, action) => {
  if (action.payload.data === undefined) {
    createNotification("error", "Something went wrong", 3000);
    return {
      ...state,
      teamMemberIsLoading: false,
      loadingMemberId: null,
    };
  }
  const {
    payload: { data },
  } = action;
  if (data.status === 400) {
    createNotification("error", "Failed!! please try again", 2000);
  }
  return {
    ...state,
    teamMemberIsLoading: false,
    loadingMemberId: null,
  };
};

export const teamProjectsListRequestHandler = (state, action) => {
  return {
    ...state,
    projectListLoading: true,
  };
};
export const teamProjectsListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.status === 200) {
    return {
      ...state,
      projectListLoading: false,
      teamProjectsList: data.data.results,
      teamProjectsCount: data.data.count,
      teamProjectsPageSize: data.data.page_size,
    };
  }
  return {
    ...state,
    projectListLoading: false,
    teamProjectsList: [],
    teamProjectsCount: null,
    teamProjectsPageSize: null,
  };
};
export const teamProjectsListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    projectListLoading: false,
    teamProjectsList: [],
    teamProjectsCount: null,
    teamProjectsPageSize: null,
  };
};

export const addTeamToProjectRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, projectData, getDetails },
  } = action;
  if (data && data.status === 201 && data.data && projectData) {
    if (data.data.project_members && data.data.project_members.length === 0) {
      createNotification(
        "error",
        "All team members are already assigned to this project",
        4000
      );
    }
    let filteredList = state.teamsShortList.filter(
      (team) => team.id !== data.data.team_id
    );

    // if (
    //   state.teamDetails &&
    //   !getDetails &&
    //   data.data.project_members &&
    //   data.data.project_members.length > 0
    // ) {
    //   const details = { ...state.teamDetails };
    //   details.projects = [...details.projects, projectData];
    //   return {
    //     ...state,
    //     teamsShortList: filteredList,
    //     teamDetails: details,
    //   };
    // }

    return {
      ...state,
      teamsShortList: filteredList,
    };
  }
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_TEAM_LIST]: teamsListRequestHandler,
  [GET_TEAM_LIST_SUCCESS]: teamsListRequestSuccessHandler,
  [GET_TEAM_LIST_FAILURE]: teamsListRequestFailureHandler,

  [GET_TEAM_SHORT_LIST]: teamsShortListRequestHandler,
  [GET_TEAM_SHORT_LIST_SUCCESS]: teamsShortListRequestSuccessHandler,
  [GET_TEAM_SHORT_LIST_FAILURE]: teamsShortListRequestFailureHandler,

  [GET_TEAM_DETAILS]: teamDetailsRequestHandler,
  [GET_TEAM_DETAILS_SUCCESS]: teamDetailsRequestSuccessHandler,
  [GET_TEAM_DETAILS_FAILURE]: teamDetailsRequestFailureHandler,

  [ADD_TEAM_REQUEST]: addTeamRequestHandler,
  [ADD_TEAM_REQUEST_SUCCESS]: addTeamRequestSuccessHandler,
  [ADD_TEAM_REQUEST_FAILURE]: addTeamRequestFailureHandler,

  [CLEAR_CREATED_TEAM_ID]: clearCreatedTeamId,

  [UPDATE_TEAM_REQUEST]: updateTeamRequestHandler,
  [UPDATE_TEAM_REQUEST_SUCCESS]: updateTeamRequestSuccessHandler,
  [UPDATE_TEAM_REQUEST_FAILURE]: updateTeamRequestFailureHandler,

  [DELETE_TEAM_REQUEST]: deleteTeamRequestHandler,
  [DELETE_TEAM_REQUEST_SUCCESS]: deleteTeamRequestSuccessHandler,
  [DELETE_TEAM_REQUEST_FAILURE]: deleteTeamRequestFailureHandler,

  [GET_TEAM_MEMBERS_LIST]: teamMembersListRequestHandler,
  [GET_TEAM_MEMBERS_LIST_SUCCESS]: teamMembersListRequestSuccessHandler,
  [GET_TEAM_MEMBERS_LIST_FAILURE]: teamMembersListRequestFailureHandler,

  [CLEAR_TEAM_MEMBERS_LIST]: clearTeamMembersListRequestHandler,

  [ADD_TEAM_MEMBER]: addTeamMemberRequestHandler,
  [ADD_TEAM_MEMBER_SUCCESS]: addTeamMemberRequestSuccessHandler,
  [ADD_TEAM_MEMBER_FAILURE]: addTeamMemberRequestFailureHandler,

  [UPDATE_TEAM_MEMBER]: updateTeamMemberRequestHandler,
  [UPDATE_TEAM_MEMBER_SUCCESS]: updateTeamMemberRequestSuccessHandler,
  [UPDATE_TEAM_MEMBER_FAILURE]: updateTeamMemberRequestFailureHandler,

  [REMOVE_TEAM_MEMBER]: removeTeamMemberRequestHandler,
  [REMOVE_TEAM_MEMBER_SUCCESS]: removeTeamMemberRequestSuccessHandler,
  [REMOVE_TEAM_MEMBER_FAILURE]: removeTeamMemberRequestFailureHandler,

  [GET_TEAM_PROJECTS_LIST]: teamProjectsListRequestHandler,
  [GET_TEAM_PROJECTS_LIST_SUCCESS]: teamProjectsListRequestSuccessHandler,
  [GET_TEAM_PROJECTS_LIST_FAILURE]: teamProjectsListRequestFailureHandler,

  [ADD_TEAM_TO_PROJECT_SUCCESS]: addTeamToProjectRequestSuccessHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  memberListLoading: false,
  projectListLoading: false,
  shortListIsLoading: false,
  teamMemberIsLoading: false,
  loadingMemberId: null,
  updateTeamLoading: false,
  deleteTeamLoading: false,
  teamsList: [],
  teamsShortList: [],
  teamDetails: null,
  createdTeamId: null,
  teamMembersList: [],
  teamProjectsList: [],

  // pagination
  totalTeamsCount: null,
  teamPageSize: null,
  teamMembersCount: null,
  teamMembersPageSize: null,
  teamProjectsCount: null,
  teamProjectsPageSize: null,

  reloadTeams: false,
};

export default function verifyReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
