import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
  CommonGrid,
  InputField,
} from "../../../../styledComponents/common";
import {
  PrimaryButton,
  WhiteButton,
} from "../../../../styledComponents/buttons";
import {
  ChecklistContainer,
  StepContainer,
  CircleContainer,
  Circle,
  VerticalLine,
  StepText
} from "../../../../styledComponents/integrations";
import { IntegrationNameContainer } from "../../Setup/integrationSetupStyles";
import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";
import { SmallCardContainer } from "components/DashboardCards/cardStyles";
import ButtonTextLoader from "../../../../components/Loaders/ButtonTextLoader";
import Select from "../../../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../../../components/DropdownStyle/FilterDropDownStyle";
import { CommImageNameComp } from "custom_modules/Members/CommonComponents";

import asanaIcon from "../../../../assets/img/integrations/asana.svg";
import documentIcon from "../../../../assets/img/common/document-icon.svg";
import mailSentIcon from "../../../../assets/img/common/mail_sent.svg";
import Loader from "react-spinners/PulseLoader";


const CheckMark = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.6667 5L7.50001 14.1667L3.33334 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const Checklist = ({ step }) => {
  const steps = [
    'Connect Your Asana Account',
    'Select Your Asana Workspace',
    'Add Projects and Tasks',
    'Add Members'
  ];

  return (
    <ChecklistContainer>
      {steps.map((text, index) => (
        <StepContainer key={index}>
          <CircleContainer>
            <Circle completed={index < step} active={index===step}>
              {index < step ? <CheckMark /> : index + 1}
            </Circle>
            {index < steps.length - 1 && <VerticalLine />}
          </CircleContainer>
          <StepText>{text}</StepText>
        </StepContainer>
      ))}
    </ChecklistContainer>
  );
};


const AsanaSync = (props) => {
  const [step, setStep] = useState(1);
  const [members, setMembers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [projectCount, setProjectCount] = useState(0);
  const [issueCount, setIssueCount] = useState(0);

  const {
    history,
    selectedOrganization,
    inviteMemberBulk,
    inviteMemberLoading,
    getAsanaInfo,
    asanaInfo,
    asanaCredentials,
    connectAsanaIntegration,
    updateAsanaCredentials,
    isLoading,
    location,
  } = props;

  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectAllClicked, setSelectAllClicked] = useState(false);

  useEffect(() => {
    if(location?.syncClicked){
      setStep(2);
    }
    if (location.state && location.state.length > 0) {
      const options = location.state
        .map((item) => ({
          value: item.gid,
          label: item.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setWorkspaces(options);
    }
  }, []);

  useEffect(() => {
    if (workspaces && asanaCredentials?.workspace_gid) {
      const matchingWorkspace = workspaces.find(
        (workspace) => workspace.value === asanaCredentials.workspace_gid
      );
      if (matchingWorkspace) {
        setSelectedWorkspace(matchingWorkspace);
      }
    }
  }, [workspaces, asanaCredentials]);  

  // useEffect(() => {
  //   if (selectedOrganization && selectedOrganization.id) {
  //     const payload = {
  //       organization_id: selectedOrganization.id,
  //     };
      
  //     if(!asanaInfo){
  //       getAsanaInfo(payload);
  //     }
  //   }
  // }, [selectedOrganization]);

  const handleAddProjects = () => {
    if (selectedOrganization && selectedOrganization.id && selectedWorkspace && location.token) {
      const payload = {
        workspace_gid: selectedWorkspace.value,
        personal_access_token: location.token,
        organization_id: selectedOrganization.id
      };

      if(location?.isUpdateMode){
        updateAsanaCredentials(payload);
      }else{
        connectAsanaIntegration(payload);
      }
      setStep(2);
    }
  };

  useEffect(() => {
    if(asanaInfo){
      setProjectCount(asanaInfo?.project_count);
      setIssueCount(asanaInfo?.task_count);
    }
    if (selectedOrganization && selectedOrganization.id) {
      if(asanaInfo){
        const formattedUsers = asanaInfo.asana_users?.map((user) => ({
          avatar: user.profile_picture_url,
          name: user.name,
          email: user.email,
          color: user.color ? user.color : null,
          status: user.status,
        }));
        setMembers(formattedUsers);
      }
    }
  }, [asanaInfo, selectedOrganization]);

  const handleAddMembers = () => {
    setStep(3);
  };

  const handleSendInvitation = () => {
    if (selectedOrganization && selectedOrganization.id && selectedEmails.length > 0) {
      const payload = {
        emails: selectedEmails,
        organization_id: selectedOrganization.id
      };

      inviteMemberBulk(payload);

      setTimeout(() => {
        setStep(4);
      }, 1000);
    }
  };

  const handleBackToDetails = () => {
    if (selectedOrganization && selectedOrganization.id) {
      history.push({
        pathname: `/user/integrations/asana-details`,
      });
    }
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevEmails) =>
      prevEmails.includes(email)
        ? prevEmails.filter((e) => e !== email)
        : [...prevEmails, email]
    );
  };

  const handleSelectAll = () => {
    if(selectAllClicked){
      setSelectedEmails([]);
      
      setSelectAllClicked(false);
    }else{
      const allEmails = members?.map(member => member.email);
      setSelectedEmails(allEmails);

      setSelectAllClicked(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const onWorkspaceSelect = (e) => {
    setSelectedWorkspace(e);
  };

  const filteredMembers = members?.filter(
    member =>
      member.email.toLowerCase().includes(searchInput.toLowerCase()) ||
      member.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  const EmployeeCheckbox = ({ name, email, color, avatar, checked, onChange, index }) => {
    return (
      <CommonGrid
        columns="0.2fr 1.8fr 2fr"
        style={{
          marginTop: '11px',
          backgroundColor: checked ? '#f8f9fc' : '',
        }}
      >
        <input
          style={{ transform: 'scale(1.0)' }}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        <CommImageNameComp
          name={name}
          imgSource={avatar}
          color={color}
          index={index}
        />
        <CommonText>{email}</CommonText>
      </CommonGrid>
    );
  };

  return (
    <div className="content">
      <PageTitle>Asana Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>

      <IntegrationNameContainer style={{margin: "30px 0 0"}}>
        <CommonFlex gap="12px">
          <CommonImage size="40px" src={asanaIcon} />
          <CommonText fontWeight="600" fontSize="16px">
            Asana 
          </CommonText>
        </CommonFlex>
      </IntegrationNameContainer>

      <CommonFlex 
        gap="200px" 
        justifyContent="flex-start" 
        alignItems="flex-start" 
        margin="30px 0"
      >
        <Checklist step={step} />

        {step === 1 && (
          <SmallCardContainer style={{width: "600px", height: "600px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CommonFlex 
              width="300px" 
              direction="column" 
              alignItems="center" 
              gap="20px" 
              style={{textAlign: "center"}}
            >
              {/* <CommonImage size="100px" src={documentIcon} /> */}

              <CommonText fontSize="20px" margin="20px 0">
                Select Your Asana Workspace
              </CommonText>

              {isLoading ? (
                <Loader
                  color="#c2cce1"
                />
              ) : (
                <Select
                  value={selectedWorkspace}
                  options={workspaces}
                  onChange={(e) => onWorkspaceSelect(e)}
                  placeholder="Select Workspace..."
                  styles={FilterDropDownStyle({ height: "40px" })}
                />
              )}
              <PrimaryButton margin="40px 0" onClick={handleAddProjects}>
                Next
              </PrimaryButton>
            </CommonFlex>
          </SmallCardContainer>
        )}

        {step === 2 && (
          <SmallCardContainer style={{width: "600px", height: "600px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CommonFlex 
              width="300px" 
              direction="column" 
              alignItems="center" 
              gap="20px" 
              style={{textAlign: "center"}}
            >
              <CommonImage size="100px" src={documentIcon} />
              {isLoading ? (
                <Loader
                  color="#c2cce1"
                />
              ) : (
                <CommonText fontSize="20px" margin="20px 0">
                  We have found {projectCount?projectCount:0} { projectCount>1 ? 'projects':'project' } and {issueCount?issueCount:0} { issueCount>1 ? 'tasks':'task' } in Asana
                </CommonText>
              )}
              <PrimaryButton margin="40px 0" onClick={handleAddMembers}>
                Add
              </PrimaryButton>
            </CommonFlex>
          </SmallCardContainer>
        )}

        {step === 3 && (
          <SmallCardContainer style={{ display: "block", width: "600px", height: "650px", padding: "30px"}}>
            <CommonText fontWeight="600" fontSize="24px" style={{textAlign: "left"}}>
              Invite team members
            </CommonText>
            <CommonFlex direction="column" gap="20px" justifyContent="flex-start">
              <div style={{ position: 'relative', margin: '15px 0 10px 15px' }}>
                <InputField
                  type="text"
                  placeholder="Search team members by name or email"
                  value={searchInput}
                  onChange={handleSearchChange}
                  style={{
                    paddingRight: '30px',
                    width: '400px',
                    height: '35px',
                    borderRadius: '7px',
                    border: '1px solid #ccc',
                    paddingLeft: '10px',
                  }}
                />
                {searchInput && (
                  <button
                    onClick={() => setSearchInput('')}
                    style={{
                      position: 'absolute',
                      right: '5px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    &#x2715;
                  </button>
                )}
              </div>
              <div style={{ height: '350px', overflowY: 'auto', padding: '10px 0 0 15px' }}>
                {filteredMembers?.map((member, index) => (
                  <EmployeeCheckbox
                    key={member.email}
                    index={index}
                    name={member.name}
                    email={member.email}
                    color={member.color}
                    avatar={member.avatar}
                    checked={selectedEmails.includes(member.email)}
                    onChange={() => handleCheckboxChange(member.email)}
                  />
                ))}
              </div>

              <CommonFlex justifyContent="space-between" margin="40px 0">
                <WhiteButton
                  hoverBorderColor="#20bead" 
                  color="#20bead" 
                  onClick={handleSelectAll}
                  disabled={ !filteredMembers || filteredMembers.length===0 || isLoading}
                >
                  {selectAllClicked ? "Deselect All" : "Select All"}
                </WhiteButton>

                <CommonText>
                  {selectedEmails.length} Selected
                </CommonText>
                <PrimaryButton 
                  type="submit" 
                  onClick={handleSendInvitation}
                  disabled={ isLoading ||selectedEmails.length === 0 }
                >
                  {isLoading ? (
                    <ButtonTextLoader loadingText="Sending Invitation" fontSize="13px" />
                  ) : (
                    "Send Invitation"
                  )}
                </PrimaryButton>
              </CommonFlex>
            </CommonFlex>
          </SmallCardContainer>
        )}

        {step === 4 && (
          <SmallCardContainer style={{width: "500px", height: "600px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <CommonFlex 
              direction="column" 
              alignItems="center" 
              gap="20px" 
              style={{textAlign: "center"}}
            >
              <CommonImage size="100px" src={mailSentIcon} />
              <CommonText fontSize="20px">
                Invitation sent successfully
              </CommonText>
              <WhiteButton hoverBorderColor="#20bead" color="#20bead" margin="40px 0" onClick={handleBackToDetails}>
                Back to Asana details
              </WhiteButton>
            </CommonFlex>
          </SmallCardContainer>
        )}
      </CommonFlex>
    </div>
  );
};


export default AsanaSync;