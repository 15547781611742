import { connect } from 'react-redux';
import AsanaSync from './AsanaSync';

import { connectAsanaIntegration, inviteMemberBulk, getAsanaInfo, updateAsanaCredentials } from '../asanaIntegrationsActions';


const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.asanaIntegrations.isLoading,
  syncIsLoading: state.asanaIntegrations.syncIsLoading,
  asanaInfo: state.asanaIntegrations.asanaInfo,
  asanaCredentials: state.asanaIntegrations.asanaCredentials,
})

const mapDispatchToProps = (dispatch) => ({
  connectAsanaIntegration: (payload) =>
      dispatch(connectAsanaIntegration(payload)),
  inviteMemberBulk: (payload) => dispatch(inviteMemberBulk(payload)),
  getAsanaInfo: (payload) => dispatch(getAsanaInfo(payload)),
  updateAsanaCredentials: (payload) => 
      dispatch(updateAsanaCredentials(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AsanaSync)