//Effects
import { all, put, takeLatest, delay, throttle } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

//Constants
import {
  GET_IS_INSTALLED_ASANA,
  GET_IS_INSTALLED_ASANA_SUCCESS,
  GET_IS_INSTALLED_ASANA_FAILURE,
  CONNECT_ASANA,
  CONNECT_ASANA_SUCCESS,
  CONNECT_ASANA_FAILURE,
  SYNC_ASANA,
  SYNC_ASANA_SUCCESS,
  SYNC_ASANA_FAILURE,
  DELETE_ASANA,
  DELETE_ASANA_SUCCESS,
  DELETE_ASANA_FAILURE,
  UPDATE_ASANA,
  UPDATE_ASANA_SUCCESS,
  UPDATE_ASANA_FAILURE,
  GET_IS_ACTIVE_ASANA,
  GET_IS_ACTIVE_ASANA_SUCCESS,
  GET_IS_ACTIVE_ASANA_FAILURE,
  INVITE_MEMBER_BULK,
  INVITE_MEMBER_BULK_SUCCESS,
  INVITE_MEMBER_BULK_FAILURE,
  UPDATE_ASANA_CREDENTIALS,
  UPDATE_ASANA_CREDENTIALS_SUCCES,
  UPDATE_ASANA_CREDENTIALS_FAILURE,
  GET_ASANA_CREDENTIALS,
  GET_ASANA_CREDENTIALS_SUCCES,
  GET_ASANA_CREDENTIALS_FAILURE,
  GET_ASANA_INFO,
  GET_ASANA_INFO_SUCCESS,
  GET_ASANA_INFO_FAILURE,
} from "../../../modules/constants";


export function* getIsInstalledAsanaRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/get_is_installed/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_IS_INSTALLED_ASANA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_IS_INSTALLED_ASANA_FAILURE,
      payload: err,
    });
  }
}

export function* connectAsanaRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/`;
    const response = yield defaultApi(url, "POST", data);
    yield delay(1500);
    yield put({
      type: CONNECT_ASANA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: CONNECT_ASANA_FAILURE,
      payload: err,
    });
  }
}

export function* syncAsanaRequest({ payload }) {
  if (!payload) return;
  const { organization_id} = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/sync/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: SYNC_ASANA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: SYNC_ASANA_FAILURE,
      payload: err,
    });
  }
}

export function* deleteAsanaRequest({ payload }) {
  if (!payload) return;
  const { organization_id} = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/remove/`;
    const response = yield defaultApi(url, "DELETE");
    yield delay(1500);
    yield put({
      type: DELETE_ASANA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: DELETE_ASANA_FAILURE,
      payload: err,
    });
  }
}

export function* getIsActiveAsanaRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/get_is_active/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_IS_ACTIVE_ASANA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_IS_ACTIVE_ASANA_FAILURE,
      payload: err,
    });
  }
}

export function* updateAsanaRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/update_is_active/`;
    const response = yield defaultApi(url, "PATCH", data);
    yield delay(1500);
    yield put({
      type: UPDATE_ASANA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_ASANA_FAILURE,
      payload: err,
    });
  }
}

export function* getAsanaCredentialsRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/get_credentials/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_ASANA_CREDENTIALS_SUCCES,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ASANA_CREDENTIALS_FAILURE,
      payload: err,
    });
  }
}

export function* getAsanaInfoRequest({ payload }) {
  if (!payload) return;
  const { organization_id } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/get_info/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_ASANA_INFO_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ASANA_INFO_FAILURE,
      payload: err,
    });
  }
}

export function* updateCredentialsRequest({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/manual_integrations/asana/update_credentials/`;
    const response = yield defaultApi(url, "PATCH", data);
    yield delay(1500);
    yield put({
      type: UPDATE_ASANA_CREDENTIALS_SUCCES,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: UPDATE_ASANA_CREDENTIALS_FAILURE,
      payload: err,
    });
  }
}

export function* inviteMemberBulk({ payload }) {
  if (!payload) return;
  const { organization_id, ...data } = payload;
  try {
    const url = `${organization_id}/members/invitations/multiple/`;
    const response = yield defaultApi(url, "POST", data);
    yield delay(1500);
    yield put({
      type: INVITE_MEMBER_BULK_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: INVITE_MEMBER_BULK_FAILURE,
      payload: err,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(GET_IS_INSTALLED_ASANA, getIsInstalledAsanaRequest),
    takeLatest(GET_IS_ACTIVE_ASANA, getIsActiveAsanaRequest),
    takeLatest(CONNECT_ASANA, connectAsanaRequest),
    takeLatest(SYNC_ASANA, syncAsanaRequest),
    takeLatest(DELETE_ASANA, deleteAsanaRequest),
    takeLatest(UPDATE_ASANA, updateAsanaRequest),
    takeLatest(INVITE_MEMBER_BULK, inviteMemberBulk),
    takeLatest(UPDATE_ASANA_CREDENTIALS, updateCredentialsRequest),
    takeLatest(GET_ASANA_CREDENTIALS, getAsanaCredentialsRequest),
    takeLatest(GET_ASANA_INFO, getAsanaInfoRequest),
  ]);
}
