import {
    GET_IS_INSTALLED_ASANA,
    GET_IS_INSTALLED_ASANA_SUCCESS,
    GET_IS_INSTALLED_ASANA_FAILURE,
    CONNECT_ASANA,
    CONNECT_ASANA_SUCCESS,
    CONNECT_ASANA_FAILURE,
    SYNC_ASANA,
    SYNC_ASANA_SUCCESS,
    SYNC_ASANA_FAILURE,
    DELETE_ASANA,
    DELETE_ASANA_SUCCESS,
    DELETE_ASANA_FAILURE,
    UPDATE_ASANA,
    UPDATE_ASANA_SUCCESS,
    UPDATE_ASANA_FAILURE,
    GET_IS_ACTIVE_ASANA,
    GET_IS_ACTIVE_ASANA_SUCCESS,
    GET_IS_ACTIVE_ASANA_FAILURE,
    INVITE_MEMBER_BULK,
    INVITE_MEMBER_BULK_SUCCESS,
    INVITE_MEMBER_BULK_FAILURE,
    UPDATE_ASANA_CREDENTIALS,
    UPDATE_ASANA_CREDENTIALS_SUCCES,
    UPDATE_ASANA_CREDENTIALS_FAILURE,
    GET_ASANA_CREDENTIALS,
    GET_ASANA_CREDENTIALS_SUCCES,
    GET_ASANA_CREDENTIALS_FAILURE,
    GET_ASANA_INFO,
    GET_ASANA_INFO_SUCCESS,
    GET_ASANA_INFO_FAILURE,
  } from "../../../modules/constants";
  
  import { createNotification } from "../../../modules/notificationManager";
  
  // ------------------------------------
  // Action handler methods
  // ------------------------------------

  export const handleGetIsInstalledAsanaRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetIsInstalledAsanaRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        isInstalledAsana: data.data.is_installed,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetIsInstalledAsanaRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleConnectAsanaRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleConnectAsanaRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200 || data.status === 201) {
      createNotification(
        "success",
        "Asana connected.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        isInstalledAsana: true,
        asanaInfo: data.data.user_project_task,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleConnectAsanaRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not connect Asana.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleSyncAsanaRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
      syncIsLoading: true,
    };
  };
  export const handleSyncAsanaRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      createNotification(
        "success",
        "Asana synced.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        syncIsLoading: false,
        asanaInfo: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
      syncIsLoading: false,
    };
  };
  export const handleSyncAsanaRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not sync Asana.",
      1000
    );
    return {
      ...state,
      isLoading: false,
      syncIsLoading: false,
    };
  };
  
  export const handleDeleteAsanaRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleDeleteAsanaRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200 || data.status === 204) {
      createNotification(
        "success",
        "Asana deleted.",
        1500
      );
      return {
        ...state,
        isLoading: false,
        isActiveAsana: false,
        isInstalledAsana: false,
        asanaInfo: null,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleDeleteAsanaRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not delete Asana.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  
  export const handleGetIsActiveAsanaRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetIsActiveAsanaRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        isActiveAsana: data.data.is_active,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetIsActiveAsanaRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleUpdateAsanaRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleUpdateAsanaRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      createNotification(
        "success",
        "Asana updated.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        isActiveAsana: data.data.is_active,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleUpdateAsanaRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not update Asana.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleUpdateCredentialsRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
      asanaInfo: null,
    };
  };
  export const handleUpdateCredentialsRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      createNotification(
        "success",
        "Credentials updated.",
        1000
      );
      return {
        ...state,
        isLoading: false,
        asanaInfo: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleUpdateCredentialsRequestFailure = (state, action) => {
    createNotification(
      "error",
      "Could not update credentials.",
      1000
    );
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleInviteBulkRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleInviteBulkRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200 || data.status === 201) {
      return {
        ...state,
        isLoading: false,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleInviteBulkRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleGetAsanaCredentialsRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetAsanaCredentialsRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        asanaCredentials: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetAsanaCredentialsRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  export const handleGetAsanaInfoRequest = (state, action) => {
    return {
      ...state,
      isLoading: true,
    };
  };
  export const handleGetAsanaInfoRequestSuccess = (state, action) => {
    const {
      payload: { data },
    } = action;
  
    if (data.status === 200) {
      return {
        ...state,
        isLoading: false,
        asanaInfo: data.data,
      };
    }
    return {
      ...state,
      isLoading: false,
    };
  };
  export const handleGetAsanaInfoRequestFailure = (state, action) => {
    return {
      ...state,
      isLoading: false,
    };
  };
  
  // Action Handlers
  const ACTION_HANDLERS = {
  
    [GET_IS_INSTALLED_ASANA]: handleGetIsInstalledAsanaRequest,
    [GET_IS_INSTALLED_ASANA_SUCCESS]: handleGetIsInstalledAsanaRequestSuccess,
    [GET_IS_INSTALLED_ASANA_FAILURE]: handleGetIsInstalledAsanaRequestFailure,
    [GET_IS_ACTIVE_ASANA]: handleGetIsActiveAsanaRequest,
    [GET_IS_ACTIVE_ASANA_SUCCESS]: handleGetIsActiveAsanaRequestSuccess,
    [GET_IS_ACTIVE_ASANA_FAILURE]: handleGetIsActiveAsanaRequestFailure,
  
    [CONNECT_ASANA]: handleConnectAsanaRequest,
    [CONNECT_ASANA_SUCCESS]: handleConnectAsanaRequestSuccess,
    [CONNECT_ASANA_FAILURE]: handleConnectAsanaRequestFailure,
  
    [DELETE_ASANA]: handleDeleteAsanaRequest,
    [DELETE_ASANA_SUCCESS]: handleDeleteAsanaRequestSuccess,
    [DELETE_ASANA_FAILURE]: handleDeleteAsanaRequestFailure,
  
    [SYNC_ASANA]: handleSyncAsanaRequest,
    [SYNC_ASANA_SUCCESS]: handleSyncAsanaRequestSuccess,
    [SYNC_ASANA_FAILURE]: handleSyncAsanaRequestFailure,
  
    [UPDATE_ASANA]: handleUpdateAsanaRequest,
    [UPDATE_ASANA_SUCCESS]: handleUpdateAsanaRequestSuccess,
    [UPDATE_ASANA_FAILURE]: handleUpdateAsanaRequestFailure,
  
    [UPDATE_ASANA_CREDENTIALS]: handleUpdateCredentialsRequest,
    [UPDATE_ASANA_CREDENTIALS_SUCCES]: handleUpdateCredentialsRequestSuccess,
    [UPDATE_ASANA_CREDENTIALS_FAILURE]: handleUpdateCredentialsRequestFailure,
  
    [INVITE_MEMBER_BULK]: handleInviteBulkRequest,
    [INVITE_MEMBER_BULK_SUCCESS]: handleInviteBulkRequestSuccess,
    [INVITE_MEMBER_BULK_FAILURE]: handleInviteBulkRequestFailure,
  
    [GET_ASANA_CREDENTIALS]: handleGetAsanaCredentialsRequest,
    [GET_ASANA_CREDENTIALS_SUCCES]: handleGetAsanaCredentialsRequestSuccess,
    [GET_ASANA_CREDENTIALS_FAILURE]: handleGetAsanaCredentialsRequestFailure,
  
    [GET_ASANA_INFO]: handleGetAsanaInfoRequest,
    [GET_ASANA_INFO_SUCCESS]: handleGetAsanaInfoRequestSuccess,
    [GET_ASANA_INFO_FAILURE]: handleGetAsanaCredentialsRequestFailure,
  };
  
  // default initial state
  const initialState = {
    isLoading: false,
    syncIsLoading: false,
  
    isInstalledAsana: false,
    isActiveAsana: false,
    asanaInfo: null,
    asanaCredentials: null,
  };
  
  export default function AsanaIntegrationsReducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
  
    return handler ? handler(state, action) : state;
  }
  