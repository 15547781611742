import moment from "moment-timezone";

import {
  Container,
  RoundMiniImage,
  TableText,
  CommonText,
  CommonFlex,
} from "../../styledComponents/common";
import { TableItem } from "../../styledComponents/teams";
import { MemberNameDiv } from "../../styledComponents/members";
import { ClockInOutTableHeader, ClockInOutTableRow } from "./clockInOutStyles";

import { getFullName, getHours, toHHMMSS } from "../../utils/helper";

import { CommImageNameComp } from "../Members/CommonComponents";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";

const ClockInOutTable = ({ isLoading, history, clockInOutList }) => {
  return (
    <Container style={{ paddingBottom: "20px" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : clockInOutList && clockInOutList.length > 0 ? (
        <>
          <ClockInOutTableHeader>
            <TableItem>
              <TableText>Member Name</TableText>
            </TableItem>
            <TableItem>
              <CommonFlex justifyContent="center">
                <TableText>Date</TableText>
              </CommonFlex>
            </TableItem>
            <TableItem>
              <CommonFlex justifyContent="center">
                <TableText>Clock In</TableText>
              </CommonFlex>
            </TableItem>
            <TableItem>
              <CommonFlex justifyContent="center">
                <InfoTooltip
                  mainText="Clock Out"
                  toolTipText="Clock out occurs when the user stops the timer at the end of the day"
                  title
                  label
                  top="-55px"
                  tooltipPadding="5px 8px"
                  infoSize="17px"
                />
              </CommonFlex>
            </TableItem>
            <TableItem>
              <CommonFlex justifyContent="center">
                <TableText>Time Worked</TableText>
              </CommonFlex>
            </TableItem>
          </ClockInOutTableHeader>

          {clockInOutList.map((data, index) => (
              <ClockInOutTableRow key={index}>
                <CommonFlex direction="column" alignItems="start">
                  <div
                    style={{
                      position: "sticky",
                      top: "0",
                    }}
                  >
                    <TableItem
                      onClick={() =>
                        data.user.id &&
                        history.push(`/user/member-details?id=${data.user.id}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <CommImageNameComp
                        imgSource={data.user.avatar ?? null}
                        name={getFullName(data.user)}
                        color={data.user.color ?? null}
                        size="36px"
                        index={index}
                        hoverUnderline
                        isOnline={data.user.is_online}
                      />
                    </TableItem>
                  </div>
                </CommonFlex>
                <TableItem>
                  <CommonFlex direction="column" gap="15px">
                    {data.attendance?.length > 0
                      ? data.attendance.map((att, index) => {
                          return (
                            <TableText name key={index}>
                              {att.date
                                ? moment(att.date, "YYYY-MM-DD").format(
                                    "DD-MM-YYYY"
                                  )
                                : "-"}
                            </TableText>
                          );
                        })
                      : "-"}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column" gap="15px">
                    {data.attendance?.length > 0
                      ? data.attendance.map((att, index) => {
                          return (
                            <TableText name key={index}>
                              {att.clock_in
                                ? moment(att.clock_in).format("LT")
                                : "-"}
                            </TableText>
                          );
                        })
                      : "-"}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column" gap="15px">
                    {data.attendance?.length > 0
                      ? data.attendance.map((att, index) => {
                          return (
                            <TableText name key={index}>
                              {att.clock_out &&
                              moment(att.clock_out).isBefore(moment(), "day")
                                ? moment(att.clock_out).format("LT")
                                : "-"}
                            </TableText>
                          );
                        })
                      : "-"}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <CommonFlex direction="column" gap="15px">
                    {data.attendance?.length > 0
                      ? data.attendance.map((att, index) => {
                          return (
                            <TableText name key={index}>
                              {att.duration
                                ? getHours(toHHMMSS(att.duration))
                                : "-"}
                            </TableText>
                          );
                        })
                      : "-"}
                  </CommonFlex>
                </TableItem>
              </ClockInOutTableRow>
          ))}
        </>
      ) : (
        <NoDataComponent />
      )}
    </Container>
  );
};

export default ClockInOutTable;
