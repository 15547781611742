import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
  InputWithLabelSection,
  InputLabel,
  InputField,
  FormAlert,
  ColoredText,
} from "../../../styledComponents/common";

import {
  PrimaryButton,
  WhiteButton,
} from "../../../styledComponents/buttons";
import { IntegrationNameContainer } from "../Setup/integrationSetupStyles";
import { SmallCardContainer } from "components/DashboardCards/cardStyles";
import { RedStar } from "../../../styledComponents/members";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import apployeLogo from "../../../assets/img/integrations/apploye-transparent.svg";
import asanaIcon from "../../../assets/img/integrations/asana-transparent.svg";
import arrowIcon from "../../../assets/img/common/arrow.svg";
import CopyComponent from "components/CopyText/CopyComponent";
import { createNotification } from "../../../modules/notificationManager";

const AsanaIntegration = (props) => {
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [webhookSecret, setWebhookSecret] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isConnectClicked, setIsConnectClicked] = useState(false);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);
  const [workspaces, setWorkspaces] = useState(null);

  const location = useLocation();

  const {
    history,
    selectedOrganization,
    isLoading,
    syncIsLoading,
    getAsanaCredentials,
    updateAsanaCredentials,
    asanaInfo,
    asanaCredentials,
  } = props;

  useEffect(() => {
    if (
      selectedOrganization && 
      selectedOrganization.id && 
      location.pathname === '/user/integrations/asana-update-credentials'
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getAsanaCredentials(payload);

      setIsUpdateMode(true);
    }
  }, [selectedOrganization, location, asanaInfo]);

  useEffect(() => {
    if (asanaCredentials) {
      // setUrl(asanaCredentials.url || "");
      // setEmail(asanaCredentials.email || "");
      setApiToken(asanaCredentials.personal_access_token || "");
      // setWebhookSecret(asanaCredentials.webhook_secret || "");
    }
  },[asanaCredentials]);

  const getAsanaWorkspaces = async (payload) => {
    const { api_token } = payload;
  
    try {
      const response = await fetch("https://app.asana.com/api/1.0/workspaces", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${api_token}`,
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        setWorkspaces(data.data);
        createNotification("success", "Asana access allowed.", 1000);
      } else {
        console.error("Failed to fetch workspaces. Response:", response);
        createNotification("error", "Could not allow access.", 1000);
      }
    } catch (error) {
      console.error("Error fetching workspaces:", error);
      createNotification("error", "Could not allow access.", 1000);
    }
  };  

  const handleSubmit = () => {
    if (checkError()) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          // email: email,
          api_token: apiToken,
          // webhook_secret: webhookSecret,
        };

        if (isUpdateMode) {
          setIsUpdateClicked(true);
          // updateAsanaCredentials(payload);
          getAsanaWorkspaces(payload);
        } else {
          setIsConnectClicked(true);
          // connectAsanaIntegration({...payload, url: url});
          getAsanaWorkspaces(payload);
        }
      }
    }
  };

  useEffect(() => {
    if (workspaces && (isUpdateClicked | isConnectClicked)) {
      setIsConnectClicked(false);
      setIsUpdateClicked(false);
      history.push({
        pathname: `/user/integrations/asana-sync`,
        state: workspaces,
        token: apiToken,
        isUpdateMode: isUpdateMode,
      });
    }
  }, [workspaces]);

  const cancelFunction = () => {
    if(isUpdateMode){
      history.push({
        pathname: `/user/integrations/asana-details`,
      });
    }else{
      history.push({
        pathname: `/user/integrations`,
      });
    }
  };

  useEffect(() => {
    if (asanaInfo && (isUpdateClicked | isConnectClicked)) {
      setIsConnectClicked(false);
      setIsUpdateClicked(false);
      history.push({
        pathname: `/user/integrations/asana-sync`,
      });
    }
  },[asanaInfo]);

  const checkError = () => {
    let errors = {};
    let formIsValid = true;

    // if (!isUpdateMode && !url.trim()) {
    //   formIsValid = false;
    //   errors["url"] = "Application URL is required";
    // }

    // if (!email.trim()) {
    //   formIsValid = false;
    //   errors["email"] = "Admin Email is required";
    // }

    if (!apiToken.trim()) {
      formIsValid = false;
      errors["api_token"] = "Personal Access Token is required.";
    }

    // if (!webhookSecret.trim()) {
    //   formIsValid = false;
    //   errors["webhook_secret"] = "Webhook Secret is required";
    // }

    setSaveErrors(errors);
    return formIsValid;
  };

  return (
    <div className="content">
      <ContentHeader margin="0 ">
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>

      <CommonFlex 
        justifyContent="center" 
        margin="0"
      >
        <IntegrationNameContainer>
          <CommonFlex 
            gap="12px" 
            justifyContent="center"
          >            
            <CommonImage size="40px" src={asanaIcon} />
            <CommonFlex direction="column" gap="2px">
              <CommonImage size="15px" src={arrowIcon} style={{ transform: "rotate(180deg)" }} />
              <CommonImage size="15px" src={arrowIcon} />
            </CommonFlex>
            <CommonImage size="40px" src={apployeLogo} />
          </CommonFlex>
          <CommonText 
            fontWeight="600" 
            fontSize="24px" 
            style={{ textAlign: "center", width: "531px", margin: "20px 0 10px 0"}}
          >
            Apploye is requesting access to your 
            Asana account
          </CommonText>
        </IntegrationNameContainer>
      </CommonFlex>

      <CommonFlex 
        margin="20px 0 0 0" 
        gap="80px"
        justifyContent="center"
      >
        <SmallCardContainer style={{ width: "530px", height: "430px", padding: "40px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CommonFlex direction="column" gap="200px">
            <CommonFlex direction="column" gap="30px">
              {/* <InputWithLabelSection width="400px">
                <InputLabel>
                  Application URL <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="text"
                  id="url"
                  placeholder="Enter the Atlassian application URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  disabled={isUpdateMode}
                  error={saveErrors && saveErrors["url"]}
                />
                {saveErrors && saveErrors["url"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["url"]}</FormAlert>
                )}
              </InputWithLabelSection> */}

              {/* <InputWithLabelSection width="400px">
                <InputLabel>
                  Admin Email <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="email"
                  id="email"
                  placeholder="Enter the owner's or admin's email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={saveErrors && saveErrors["email"]}
                />
                {saveErrors && saveErrors["email"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["email"]}</FormAlert>
                )}
              </InputWithLabelSection> */}

              <InputWithLabelSection width="400px">
                <InputLabel>
                  Add your Personal Access Token <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="password"
                  id="api_token"
                  placeholder="Enter your Personal Access token"
                  value={apiToken}
                  onChange={(e) => setApiToken(e.target.value)}
                  error={saveErrors && saveErrors["api_token"]}
                />
                {saveErrors && saveErrors["api_token"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["api_token"]}</FormAlert>
                )}
              </InputWithLabelSection>

              {/* <InputWithLabelSection width="400px">
                <InputLabel>
                  Webhook Secret <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="password"
                  id="webhook_secret"
                  placeholder="Enter the webhook secret for secure communication"
                  value={webhookSecret}
                  onChange={(e) => setWebhookSecret(e.target.value)}
                  error={saveErrors && saveErrors["webhook_secret"]}
                />
                {saveErrors && saveErrors["webhook_secret"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["webhook_secret"]}</FormAlert>
                )}
              </InputWithLabelSection> */}

            </CommonFlex>

            <CommonFlex justifyContent="space-between" width="100%">
              <WhiteButton 
                hoverBorderColor="#20bead" 
                color="#20bead" 
                type="cancel" 
                onClick={() => cancelFunction()}
              >
                Cancel
              </WhiteButton>
              <PrimaryButton type="submit" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? (
                  <ButtonTextLoader loadingText={isUpdateMode ? "Updating" : "Connecting"} fontSize="13px" />
                ) : (
                  isUpdateMode ? "Update" : "Allow access"
                )}
              </PrimaryButton>
            </CommonFlex>
          </CommonFlex>
        </SmallCardContainer>

        <SmallCardContainer style={{ width: "530px", height: "430px", padding: "50px", overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none"}}>
          <CommonFlex justifyContent="space-between">
            <CommonText fontWeight="600" fontSize="18px" margin="0 0 20px">
              Asana Setup Guide
            </CommonText>
            <CommonText fontSize="12px" margin="0 0 20px">
              ➡️&nbsp;
              <a 
                href="https://apploye.com/help/apploye-asana-integration-guide/"
                target="_blank" 
                rel="noopener noreferrer" 
              >
                See More Details
              </a>
            </CommonText>
          </CommonFlex>
          <CommonText fontSize="12px">
            <strong>Step 1: Access the "My Apps" Page</strong><br />
            <ul>
              <li>Head over to <a href="https://app.asana.com/0/my-apps" target="_blank" rel="noopener noreferrer" >Asana My Apps</a>. If you're not already signed in, log in with your Asana credentials.</li>
            </ul>
          </CommonText>
          <CommonText fontSize="12px">
            <strong>Step 2: Create a New Personal Access Token</strong><br />
            <ul>
              <li>Once you’re on the "My Apps" page, click <strong>Create new token</strong>. Give your token a memorable name (like “MyProjectToken”), then confirm to generate it.</li>
            </ul>
          </CommonText>
          <CommonText fontSize="12px">
            <strong>Step 3: Copy Your Token Right Away</strong><br />
            <ul>
              <li>This is crucial! As soon as you see the token, copy it to a safe place—once you leave the page, you won't be able to view it again.</li>
            </ul>
          </CommonText>
        </SmallCardContainer>
      </CommonFlex>
    </div>
  );
};

export default AsanaIntegration;