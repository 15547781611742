import { connect } from "react-redux";

// Component
import TimesheetDetailsPanel from "./TimesheetDetailsPanel";
// Actions
import {
  getDailyTimesheets,
  deleteDailyTimesheets,
  clearDailyTimesheetsList,
} from "../timesheetActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  dailyTimesheetLoading: state.timesheet.dailyTimesheetLoading,
  deleteTimesheetLoading: state.timesheet.deleteTimesheetLoading,
  timesheetDeletedFlag: state.timesheet.timesheetDeletedFlag,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDailyTimesheets: (payload) => dispatch(getDailyTimesheets(payload)),
  deleteTimesheet: (payload) => dispatch(deleteDailyTimesheets(payload)),
  clearDailyTimesheetsList: () => dispatch(clearDailyTimesheetsList()),
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetDetailsPanel);
