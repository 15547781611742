import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import TagManager from "react-gtm-module";
import { getFullName, validateEmail } from "../../utils/helper";

import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  AuthCardTitle,
  AuthCardSubtitle,
  AuthForm,
  AuthFormAlert,
  AuthInputContainer,
  AuthSubmitButton,
} from "../../styledComponents/authLayout";

import AuthPageLeftSection from "../../components/AuthLayout/AuthPageLeftSection";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import CodeVerificationInput from "../../components/CodeVerificationInput/CodeVerificationInput";

const Card = styled(AuthCard)`
  min-height: 60%;
  padding: 40px 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled(AuthForm)`
  margin-top: 0 !important;
`;

const CodeInputContainer = styled(AuthInputContainer)`
  display: flex;
  justify-content: center;
`;

const CodeInputLabel = styled.p`
  margin: 45px 0 0 0;
  padding: 0px 15px;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.labelText};
`;

const SubmitButton = styled(AuthSubmitButton)`
  margin-top: 20px;
`;

// const TimerContainer = styled.div`
//   height: 50px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: rgba(241, 87, 98, 0.05);
//   border-radius: 6px;
//   margin: ${(props) =>
//     props.timerRunning ? "30px 0px 25px" : "30px 0px 15px"};
// `;
// const TimerLabelText = styled.p`
//   margin: 0;
//   color: rgba(47, 57, 78, 0.6);
//   font-size: 14px;
// `;
// const TimerText = styled.p`
//   margin: 0;
//   color: #f15762;
//   font-size: 15px;
//   font-weight: 600;
//   margin-left: 5px;
// `;

const ResendCodeLink = styled.div`
  height: 20px;
  color: ${({ theme }) => theme.colors.main.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  cursor: pointer;
  margin-top: 20px;
`;

// const CountdownTimer = (props) => {
//   useEffect(() => {
//     if (props.timerRunning && props.time > 0) {
//       setTimeout(() => props.setTime(props.time - 1), 1000);
//     }
//   }, [props.time, props.timerRunning]);

//   const formatTime = (time) => {
//     let seconds = time % 60;
//     let minutes = Math.floor(time / 60);
//     minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
//     seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
//     return `${minutes} : ${seconds}`;
//   };
//   return (
//     <TimerContainer timerRunning={props.timerRunning}>
//       <TimerLabelText>
//         {props.timerRunning ? "The code expires in" : "The last code expired"}{" "}
//       </TimerLabelText>
//       {props.time && props.time > 0 ? (
//         <TimerText>{formatTime(props.time)}</TimerText>
//       ) : null}
//     </TimerContainer>
//   );
// };

const VerificationCode = ({
  history,
  location,
  verifyCode,
  resendVerificationCode,
  resetVerifyCode,
  verificationCode,
  isLoading,
  advertise,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };
  const [query, setQuery] = useState(null);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    let token = localStorage.getItem("access_token");

    if (token || !location.search) {
      history.push("/auth/login");
      return;
    }

    const queryParsed = queryString.parse(location.search);
    setQuery(queryParsed);

    const isValidEmail =
      queryParsed?.email?.length > 0 && validateEmail(queryParsed.email);
    if (!isValidEmail) {
      history.push("/auth/login");
      return;
    }
    setEmail(queryParsed.email);
    setName(queryParsed.name);

    if (location?.state?.from === "login") {
      resendVerificationCode({ email: queryParsed.email });
    }

    return () => {
      resetVerifyCode();
    };
  }, []);

  useEffect(() => {
    if (verificationCode && verificationCode.redirect) {
      if (verificationCode.token && verificationCode.userData) {
        localStorage.setItem("access_token", verificationCode.token);
        localStorage.setItem("user_id", verificationCode.userData.user_id);
        localStorage.setItem(
          "user_profile",
          verificationCode.userData.user_profile.id
        );
        localStorage.setItem(
          "user_fullName",
          getFullName(verificationCode.userData.user_profile)
        );
        if (verificationCode.userData.user_profile.color) {
          localStorage.setItem(
            "user_profile_color",
            verificationCode.userData.user_profile.color
          );
        }
        localStorage.setItem("email", verificationCode.userData.email);

        setTimeout(() => {
          if (!verificationCode.userData.show_onboard) {
            history.push("/user/dashboard");
            return;
          }

          const state = {
            message: "onboard",
            pending_invitation: verificationCode.userData.pending_invitation,
          };

          if (query?.advertise === "google") {
            state.advertise = "google";
          }

          history.push({
            pathname: "/setup/onboarding",
            state,
          });
        }, 400);
      } else {
        history.push("/auth/login");
      }
      setTimeout(() => {
        resetVerifyCode();
      }, 500);
    }
  }, [verificationCode]);

  useEffect(() => {
    if (code && code.length === 4) {
      let payload = {
        email,
        code,
      };
      verifyCode(payload);
    }
  }, [code]);

  useEffect(() => {
    if (query && query.advertise && query.advertise === "google") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "verificationCode",
        page: location.pathname + location.search, // Send the current URL as part of the event
      });
    }
  }, [location]);

  const handleSubmitCode = (e) => {
    e.preventDefault();
    let payload = {
      email,
      code,
    };
    verifyCode(payload);
  };

  const handleResendCode = () => {
    let payload = {
      email,
    };
    resendVerificationCode(payload);
  };

  return (
    <AuthPageContainer>
      <AuthPageLeftSection />
      <AuthPageRightContainer>
        <Card>
          <AuthCardTitle>
            {name ? `${name},` : ""} you are almost done!
          </AuthCardTitle>
          <AuthCardSubtitle>
            Please type the code we sent you to your email{" "}
            {email && <strong>{email}</strong>} and verify that it's really you.
          </AuthCardSubtitle>
          <CodeInputLabel>Code</CodeInputLabel>
          <Form onSubmit={(e) => handleSubmitCode(e)}>
            <CodeInputContainer>
              <CodeVerificationInput value={code} onChange={setCode} />
            </CodeInputContainer>
            <ResendCodeLink onClick={() => handleResendCode()}>
              Resend Code
            </ResendCodeLink>
            <SubmitButton
              type="submit"
              disabled={!code || (code && code.length < 4)}
              isDisabled={!code || (code && code.length < 4)}
            >
              {isLoading ? (
                <ButtonTextLoader loadingText="Verifying" />
              ) : (
                "Verify"
              )}
            </SubmitButton>
          </Form>
        </Card>
      </AuthPageRightContainer>
    </AuthPageContainer>
  );
};

export default VerificationCode;
