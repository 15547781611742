import {
  GET_TIMESHEET_SETTINGS,
  GET_TIMESHEET_SETTINGS_SUCCESS,
  GET_TIMESHEET_SETTINGS_FAILURE,
  UPDATE_TIMESHEET_SETTINGS,
  UPDATE_TIMESHEET_SETTINGS_SUCCESS,
  UPDATE_TIMESHEET_SETTINGS_FAILURE,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_SUCCESS,
  UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_FAILURE,
} from "../../../modules/constants";

import { createNotification } from "../../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const getTimesheetSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getTimesheetSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      isLoading: false,
      allowUsersAddManualTime: data.data.allow_user_add_manual_timesheet,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const getTimesheetSettingsFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const updateTimesheetSettingsHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};
export const updateTimesheetSettingsSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Timesheet settings updated successfully.");
    return {
      ...state,
      isLoading: false,
      allowUsersAddManualTime: data.data.allow_user_add_manual_timesheet,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const updateTimesheetSettingsFailureHandler = (state, action) => {
  createNotification(
    "error",
    "Timesheet settings update failed. Please try again."
  );
  return {
    ...state,
    isLoading: false,
  };
};

export const getMemberwiseTimesheetApprovalSettingsHandler = (
  state,
  action
) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getMemberwiseTimesheetApprovalSettingsSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    totalMembersCount: data.data.count,
    memberwiseTimesheetApprovalSettings: data.data.results,
  };
};
export const getMemberwiseTimesheetApprovalSettingsFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
    isLoading: false,
    totalMembersCount: null,
    memberwiseTimesheetApprovalSettings: [],
  };
};

export const updateMemberwiseTimesheetApprovalSettingsHandler = (
  state,
  action
) => {
  return {
    ...state,
  };
};
export const updateMemberwiseTimesheetApprovalSettingsSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    if (data.data?.require_timesheet_approval) {
      createNotification("success", "Timesheet approval enabled.");
    } else {
      createNotification("success", "Timesheet approval disabled.");
    }
    const approvalSettingsList = state.memberwiseTimesheetApprovalSettings;
    if (approvalSettingsList && approvalSettingsList.length > 0) {
      approvalSettingsList.some((settings) => {
        
        if (settings.id === data.data.id) {
          settings.require_timesheet_approval =
            data.data.require_timesheet_approval;
          return true;
        } else {
          return false;
        }
      }
    );
    }
    return {
      ...state,
      memberwiseTimesheetApprovalSettings: [...approvalSettingsList],
    };
  }
  return {
    ...state,
  };
};
export const updateMemberwiseTimesheetApprovalSettingsFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
  };
};

export const updateOrganizationTimesheetApprovalSettingsHandler = (
  state,
  action
) => {
  const {
    payload: { require_timesheet_approval },
  } = action;
  if (require_timesheet_approval) {
    return {
      ...state,
      orgApprovalTurnOnLoading: true,
    };
  } else {
    return {
      ...state,
      orgApprovalTurnOffLoading: true,
    };
  }
};
export const updateOrganizationTimesheetApprovalSettingsSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data, payloadData },
  } = action;
  if (data.status === 200) {
    const approvalSettingsList = JSON.parse(
      JSON.stringify(state.memberwiseTimesheetApprovalSettings)
    );
    if (approvalSettingsList && approvalSettingsList.length > 0) {
      approvalSettingsList.forEach((settings) => {
        settings.require_timesheet_approval =
          payloadData.require_timesheet_approval;
      });
    }
    return {
      ...state,
      orgApprovalTurnOnLoading: false,
      orgApprovalTurnOffLoading: false,
      memberwiseTimesheetApprovalSettings: [...approvalSettingsList],
    };
  }
  return {
    ...state,
    orgApprovalTurnOnLoading: false,
    orgApprovalTurnOffLoading: false,
  };
};
export const updateOrganizationTimesheetApprovalSettingsFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
    orgApprovalTurnOnLoading: false,
    orgApprovalTurnOffLoading: false,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_TIMESHEET_SETTINGS]: getTimesheetSettingsHandler,
  [GET_TIMESHEET_SETTINGS_SUCCESS]: getTimesheetSettingsSuccessHandler,
  [GET_TIMESHEET_SETTINGS_FAILURE]: getTimesheetSettingsFailureHandler,

  [UPDATE_TIMESHEET_SETTINGS]: updateTimesheetSettingsHandler,
  [UPDATE_TIMESHEET_SETTINGS_SUCCESS]: updateTimesheetSettingsSuccessHandler,
  [UPDATE_TIMESHEET_SETTINGS_FAILURE]: updateTimesheetSettingsFailureHandler,

  [GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS]:
    getMemberwiseTimesheetApprovalSettingsHandler,
  [GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS]:
    getMemberwiseTimesheetApprovalSettingsSuccessHandler,
  [GET_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE]:
    getMemberwiseTimesheetApprovalSettingsFailureHandler,

  [UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS]:
    updateMemberwiseTimesheetApprovalSettingsHandler,
  [UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_SUCCESS]:
    updateMemberwiseTimesheetApprovalSettingsSuccessHandler,
  [UPDATE_MEMBERWISE_TIMESHEET_APPROVAL_SETTINGS_FAILURE]:
    updateMemberwiseTimesheetApprovalSettingsFailureHandler,

  [UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS]:
    updateOrganizationTimesheetApprovalSettingsHandler,
  [UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_SUCCESS]:
    updateOrganizationTimesheetApprovalSettingsSuccessHandler,
  [UPDATE_ORGANIZATION_TIMESHEET_APPROVAL_SETTINGS_FAILURE]:
    updateOrganizationTimesheetApprovalSettingsFailureHandler,
};

// default initial state
const initialState = {
  isLoading: false,
  orgApprovalTurnOnLoading: false,
  orgApprovalTurnOffLoading: false,

  allowUsersAddManualTime: true,

  memberwiseTimesheetApprovalSettings: [],

  // pagination
  totalMembersCount: null,
};

export default function timesheetSettingsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
