import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CheckBox } from "styledComponents/payroll";

import Tick from "../../../../assets/img/icons/tick.svg";
import ButtonTextLoader from "../../../../components/Loaders/ButtonTextLoader";
import {
  ColoredButton,
  WhiteButton,
} from "../../../../styledComponents/buttons";
import { CommonFlex, CommonText } from "../../../../styledComponents/common";
import { ModalButtonSection } from "../../../../styledComponents/members";

const ToggleTimeSheetApprovalModal = ({
  isOpen,
  toggle,
  actionFunction,
  isLoading,
  action,
  memberCount,
}) => {
  const [functionInProgress, setFunctionInProgress] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  useEffect(() => {
    setConfirmed(false);
  }, [isOpen]);

  useEffect(() => {
    if (functionInProgress === false) {
      const timer = setTimeout(() => {
        toggle();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [functionInProgress]);                                                                                                                                                                                                                                                                                                                                                                                            
  useEffect(() => {
    if (isLoading) {
      setFunctionInProgress(true);
    } else if (isLoading === false && functionInProgress === true) {
      setFunctionInProgress(false);
    }
  }, [isLoading]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          Are you sure you want approval status to turn {action} for all{" "}
          {memberCount} members?
        </CommonText>
        <CommonText style={{ paddingLeft: `15px`, paddingTop: "20px" }}>
          This action cannot be undone!
        </CommonText>
        <CommonFlex style={{ paddingLeft: `15px`, paddingTop: "30px" }}>
          <CheckBox onClick={() => setConfirmed(!confirmed)}>
            {confirmed && (
              <img src={Tick} alt="tick" width="20px" height="20px" />
            )}
          </CheckBox>{" "}
          <CommonText>I agree</CommonText>
        </CommonFlex>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          {action && (
            <ColoredButton
              type={action == "OFF" ? "delete" : ""}
              disabled={!confirmed}
              padding={isLoading ? "0 15px" : "0 30px"}
              onClick={() =>
                confirmed &&
                actionFunction({
                  turnOn: action == "OFF" ? false : true,
                })
              }
            >
              {isLoading ? (
                <ButtonTextLoader
                  loadingText={action == "OFF" ? "Turning Off" : "Turning On"}
                  fontSize="13px"
                />
              ) : (
                <> {action == "OFF" ? "Turn Off" : "Turn On"}</>
              )}
            </ColoredButton>
          )}
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ToggleTimeSheetApprovalModal;
