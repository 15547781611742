import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
  InputWithLabelSection,
  InputLabel,
  InputField,
  FormAlert,
} from "../../../styledComponents/common";

import {
  PrimaryButton,
  WhiteButton,
} from "../../../styledComponents/buttons";
import { IntegrationNameContainer } from "../Setup/integrationSetupStyles";
import { SmallCardContainer } from "components/DashboardCards/cardStyles";
import { RedStar } from "../../../styledComponents/members";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import apployeLogo from "../../../assets/img/integrations/apploye-transparent.svg";
import jiraIcon from "../../../assets/img/integrations/jira-transparent.svg";
import arrowIcon from "../../../assets/img/common/arrow.svg";
import CopyComponent from "components/CopyText/CopyComponent";

const JiraIntegration = (props) => {
  const [formData, setFormData] = useState({
    url: "",
    email: "",
    api_token: "",
    webhook_secret: "",
  });
  const [url, setUrl] = useState("");
  const [email, setEmail] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [webhookSecret, setWebhookSecret] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [isConnectClicked, setIsConnectClicked] = useState(false);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);

  const location = useLocation();

  const {
    history,
    selectedOrganization,
    isLoading,
    syncIsLoading,
    connectJiraIntegration,
    getJiraCredentials,
    updateJiraCredentials,
    jiraInfo,
    jiraCredentials,
  } = props;

  useEffect(() => {
    if (
      selectedOrganization && 
      selectedOrganization.id && 
      location.pathname === '/user/integrations/jira-update-credentials' 
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getJiraCredentials(payload);

      setIsUpdateMode(true);
    }
  }, [selectedOrganization, location, jiraInfo]);

  useEffect(() => {
    if (jiraCredentials) {
      setUrl(jiraCredentials.url || "");
      setEmail(jiraCredentials.email || "");
      setApiToken(jiraCredentials.api_token || "");
      setWebhookSecret(jiraCredentials.webhook_secret || "");
    }
  },[jiraCredentials]);

  const handleSubmit = () => {
    if (checkError()) {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
          email: email,
          api_token: apiToken,
          webhook_secret: webhookSecret,
        };

        if (isUpdateMode) {
          setIsUpdateClicked(true);
          updateJiraCredentials(payload);
        } else {
          setIsConnectClicked(true);
          connectJiraIntegration({...payload, url: url});
        }
      }
    }
  };

  const cancelFunction = () => {
    if(isUpdateMode){
      history.push({
        pathname: `/user/integrations/jira-details`,
      });
    }else{
      history.push({
        pathname: `/user/integrations`,
      });
    }
  };

  useEffect(() => {
    if (jiraInfo && (isUpdateClicked | isConnectClicked)) {
      setIsConnectClicked(false);
      setIsUpdateClicked(false);
      history.push({
        pathname: `/user/integrations/jira-sync`,
      });
    }
  },[jiraInfo]);

  const checkError = () => {
    let errors = {};
    let formIsValid = true;

    if (!isUpdateMode && !url.trim()) {
      formIsValid = false;
      errors["url"] = "Application URL is required";
    }

    if (!email.trim()) {
      formIsValid = false;
      errors["email"] = "Admin Email is required";
    }

    if (!apiToken.trim()) {
      formIsValid = false;
      errors["api_token"] = "API Token is required";
    }

    if (!webhookSecret.trim()) {
      formIsValid = false;
      errors["webhook_secret"] = "Webhook Secret is required";
    }

    setSaveErrors(errors);
    return formIsValid;
  };

  return (
    <div className="content">
      <ContentHeader margin="0 ">
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>

      <CommonFlex 
        justifyContent="center" 
        margin="0"
      >
        <IntegrationNameContainer>
          <CommonFlex 
            gap="12px" 
            justifyContent="center"
          >            
            <CommonImage size="40px" src={jiraIcon} />
            <CommonFlex direction="column" gap="2px">
              <CommonImage size="15px" src={arrowIcon} style={{ transform: "rotate(180deg)" }} />
              <CommonImage size="15px" src={arrowIcon} />
            </CommonFlex>
            <CommonImage size="40px" src={apployeLogo} />
          </CommonFlex>
          <CommonText 
            fontWeight="600" 
            fontSize="24px" 
            style={{ textAlign: "center", width: "531px", margin: "20px 0 10px 0"}}
          >
            Apploye is requesting access to your 
            Atlassian account
          </CommonText>
        </IntegrationNameContainer>
      </CommonFlex>

      <CommonFlex 
        margin="20px 0 0 0" 
        gap="80px"
        justifyContent="center"
      >
        <SmallCardContainer style={{ width: "631px", height: "700px", padding: "40px", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <CommonFlex direction="column" gap="100px">
            <CommonFlex direction="column" gap="30px">
              <InputWithLabelSection width="400px">
                <InputLabel>
                  Application URL <RedStar>*</RedStar>
                </InputLabel>
                
                <InputField
                  type="text"
                  id="url"
                  placeholder="Enter the Atlassian application URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  disabled={isUpdateMode}
                  error={saveErrors && saveErrors["url"]}
                />
                {saveErrors && saveErrors["url"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["url"]}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection width="400px">
                <InputLabel>
                  Admin Email <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="email"
                  id="email"
                  placeholder="Enter the owner's or admin's email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={saveErrors && saveErrors["email"]}
                />
                {saveErrors && saveErrors["email"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["email"]}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection width="400px">
                <InputLabel>
                  API Token <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="password"
                  id="api_token"
                  placeholder="Enter your Atlassian API token"
                  value={apiToken}
                  onChange={(e) => setApiToken(e.target.value)}
                  error={saveErrors && saveErrors["api_token"]}
                />
                {saveErrors && saveErrors["api_token"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["api_token"]}</FormAlert>
                )}
              </InputWithLabelSection>

              <InputWithLabelSection width="400px">
                <InputLabel>
                  Webhook Secret <RedStar>*</RedStar>
                </InputLabel>
                <InputField
                  type="password"
                  id="webhook_secret"
                  placeholder="Enter the webhook secret for secure communication"
                  value={webhookSecret}
                  onChange={(e) => setWebhookSecret(e.target.value)}
                  error={saveErrors && saveErrors["webhook_secret"]}
                />
                {saveErrors && saveErrors["webhook_secret"] && (
                  <FormAlert margin="5px 0 0 0">{saveErrors["webhook_secret"]}</FormAlert>
                )}
              </InputWithLabelSection>
            </CommonFlex>

            <CommonFlex justifyContent="space-between" width="100%">
              <WhiteButton 
                hoverBorderColor="#20bead" 
                color="#20bead" 
                type="cancel" 
                onClick={() => cancelFunction()}
              >
                Cancel
              </WhiteButton>
              <PrimaryButton type="submit" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? (
                  <ButtonTextLoader loadingText={isUpdateMode ? "Updating" : "Connecting"} fontSize="13px" />
                ) : (
                  isUpdateMode ? "Update" : "Allow access"
                )}
              </PrimaryButton>
            </CommonFlex>
          </CommonFlex>
        </SmallCardContainer>

        <SmallCardContainer style={{ width: "631px", height: "700px", padding: "50px", overflowY: "auto", scrollbarWidth: "none", msOverflowStyle: "none"}}>
          <CommonFlex justifyContent="space-between">
            <CommonText fontWeight="600" fontSize="18px" margin="0 0 20px">
              Jira Setup Guide
            </CommonText>
            <CommonText fontSize="12px" margin="0 0 20px">
              ➡️&nbsp;
              <a 
                href="https://apploye.com/help/apploye-jira-integration-guide/"
                style={{ color: "blue" }} 
                target="_blank" 
                rel="noopener noreferrer" 
              >
                See More Details
              </a>
            </CommonText>
          </CommonFlex>
          <CommonText fontSize="12px">
            <strong>Step 1: Application URL</strong><br />
            <ul>
              <li>Copy your Jira application URL (e.g. https://yourcompany.atlassian.net).</li>
            </ul>
          </CommonText>
          <CommonText fontSize="12px">
            <strong>Step 2: Admin Email</strong><br />
            <ul>
              <li>Use the admin or primary contact email associated with your Atlassian account.</li>
            </ul>
          </CommonText>
          <CommonText fontSize="12px">
            <strong>Step 3: Generate API Token</strong><br />
            <ul>
              <li>Go to Profile - Manage Your Account.</li>
              <li>Select Security - Create and Manage API Tokens.</li>
              <li>Click Create API Token, name it after your organization, and select Create.</li>
              <li><strong>Important</strong>: Copy your API token immediately, as you won't be able to view it again.</li>
            </ul>
          </CommonText>
          <CommonText fontSize="12px">
            <strong>Step 4: Create Webhook Secret</strong><br />
            <ul>
              <li>Go to Settings - System.</li>
              <li>Under Advanced, choose WebHooks and click Create WebHook.</li>
              <li>Name the webhook with your organization name.</li>
              <li>In the URL field, paste 
                <CopyComponent 
                  text="https://mwa.services.apploye.com/api/hooks/jira_webhook/" 
                  color="#20BEAD"
                />
              </li>
              <li>Click Generate Secret and copy the secret immediately for secure storage.</li>
            </ul>
          </CommonText>
          <CommonText fontSize="12px">
            <strong>Step 5: Configure Webhook Events</strong><br />
            <ul>
              <li>Select specific events to trigger the webhook:</li>
              <ul>
                <li>
                  Issue-Related Events
                  <ul>
                    <li>Issue Created</li>
                    <li>Issue Updated</li>
                    <li>Issue Deleted</li>
                  </ul>
                </li>
                <li>
                  Project-Related Events
                  <ul>
                    <li>Project Created</li>
                    <li>Project Updated</li>
                    <li>Project Deleted</li>
                  </ul>
                </li>
              </ul>
              <li>Once done, click Create to finalize the webhook setup.</li>
            </ul>
          </CommonText>
        </SmallCardContainer>
      </CommonFlex>
    </div>
  );
};

export default JiraIntegration;