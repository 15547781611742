import { connect } from "react-redux";

// Component
import ActivityScreenshots from "./ActivityScreenshots";
import { getOrganizationMembersList } from "../../Projects/projectsActions";
import { getClockInOutList } from "../../ClockInOut/clockInOutActions";

//Actions
import {
  getScreenshotList,
  sortScreenshots,
  addNotes,
  allNotesScreenshot,
  deleteScreenshot,
  getUserActivity,
} from "../activityActions";
import { getLiveMembersList } from "../../LiveFeed/liveFeedActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  screenshots: state.activity.screenshots,
  //sortType: state.activity.sortType,
  screenshotNotes: state.activity.screenshotNotes,
  allNotes: state.activity.allNotes,
  isLoading: state.activity.isLoading,
  deleteIsLoading: state.activity.deleteScreenshotIsLoading,
  allNotesIsLoading: state.activity.allNotesIsLoading,
  addNoteIsLoading: state.activity.addNoteIsLoading,
  userActivity: state.activity.userActivity,
  //clock in-out
  clockInOutList: state.clockInOut.clockInOutList,
  isLoadingClockInOut: state.clockInOut.isLoading,
  liveMembersList: state.liveFeed.liveMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClockInOutList: (payload) => dispatch(getClockInOutList(payload)),
  getScreenshotList: (payload) => dispatch(getScreenshotList(payload)),
  getUserActivity: (payload) => dispatch(getUserActivity(payload)),
  sortScreenshots: (payload) => dispatch(sortScreenshots(payload)),
  deleteScreenshot: (payload) => dispatch(deleteScreenshot(payload)),
  allNotesScreenshot: (payload) => dispatch(allNotesScreenshot(payload)),
  addNotes: (payload) => dispatch(addNotes(payload)),
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
  getLiveMembersList: (payload) => dispatch(getLiveMembersList(payload)),
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityScreenshots);
