import { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import LeftArrow_3 from "../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../assets/img/icons/RightArrow_3.svg";
import {
  InputWithLabelSection,
  FilterLabel,
  CommonGrid,
  CommonImage,
  CommonFlex,
} from "../../styledComponents/common";
import { FilterButton } from "../../styledComponents/buttons";
import {
  FilterSection,
  FilterInnerSection,
  IconArrow,
} from "../../styledComponents/Activity";

import CommonFilter from "../../components/CommonFilter/commonFilterContainer";

import TimezoneDropdown from "../../components/TimezoneDropdown/TimezoneDropdown";
import { getDateLabel } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { setPathName } from "../../filterReducer";

const ActivityFilter = (props) => {
  const dispatch = useDispatch();
  const activityUrls = [
    "/user/activity/screenshots",
    "/user/activity/urls",
    "/user/activity/apps",
  ];
  const [selectedDate, setSelectedDate] = useState(
    activityUrls.includes(props.filters?.pathname) &&
      props.filters?.selectedDate
      ? props.filters?.selectedDate
      : moment()
  );
  const [selectedProject, setSelectedProject] = useState(
    activityUrls.includes(props.filters?.pathname) &&
      props.filters?.selectedProject
      ? props.filters?.selectedProject
      : ""
  );
  const [selectedTask, setSelectedTask] = useState(
    activityUrls.includes(props.filters?.pathname) &&
      props.filters?.selectedTask
      ? props.filters?.selectedTask
      : ""
  );
  const [selectedMember, setSelectedMember] = useState(
    activityUrls.includes(props.filters?.pathname) &&
      props.filters?.selectedMember
      ? props.filters?.selectedMember
      : ""
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    activityUrls.includes(props.filters?.pathname) &&
      props.filters?.selectedTimezone
      ? props.filters?.selectedTimezone
      : null
  );
  const { selectedOrganization, getLiveMembersList } = props;

  const isToday = selectedDate.isSame(moment(), "day");
  useEffect(() => {
    dispatch(
      setPathName({
        pathname: location.pathname,
        selectedTask,
        selectedDate,
        selectedProject,
        selectedMember,
      })
    );
  }, [selectedTask, selectedDate, selectedProject, selectedMember]);

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (props.selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: props.selectedOrganization.timezone,
          offset: props.selectedOrganization.timezoneoffset,
        });
        payload.timezone = props.selectedOrganization.timezone;
      }

      // if (props.title === "Location" && localStorage.getItem("user_id")) {
      //   payload.user_id = localStorage.getItem("user_id");
      // }
      setSelectedProject(
        activityUrls.includes(props.filters?.pathname) &&
          props.filters?.selectedProject
          ? props.filters?.selectedProject
          : ""
      );
      setSelectedTask(
        activityUrls.includes(props.filters?.pathname) &&
          props.filters?.selectedTask
          ? props.filters?.selectedTask
          : ""
      );
      setSelectedMember(
        activityUrls.includes(props.filters?.pathname) &&
          props.filters?.selectedMember
          ? props.filters?.selectedMember
          : ""
      );
      if (
        activityUrls.includes(props.filters?.pathname) &&
        props.filters?.selectedMember?.id
      ) {
        payload.user_id = props.filters?.selectedMember.id;
      }
      if (
        activityUrls.includes(props.filters?.pathname) &&
        props.filters?.selectedProject?.value
      ) {
        payload.project_id = props.filters?.selectedProject.value;
      }
      if (
        activityUrls.includes(props.filters?.pathname) &&
        props.filters?.selectedTask?.value
      ) {
        payload.task_id = props.filters?.selectedTask.value;
      }

      if (props.title === "Screenshots") {
        props.setScreenshotMemberId(null);
        props.changeSortType({
          label: "Time (Old to new)",
          value: "OldToNew",
        });
      }

      actionsReq(payload);
    }
  }, [props.selectedOrganization]);

  useEffect(() => {
    if (props.fetchAllNotes) {
      if (
        props.selectedOrganization &&
        props.selectedOrganization.id &&
        selectedDate
      ) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          date: selectedDate.format("YYYY-MM-DD"),
          user_id: selectedMember
            ? selectedMember.id
            : localStorage.getItem("user_id"),
        };
        props.getAllNotes(payload);
      }
      props.setFetchAllNotes(false);
    }
  }, [props.fetchAllNotes]);

  useEffect(() => {
    if (props.reloadScreenshotList) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        const payload = {
          organization_id: props.selectedOrganization.id,
          date: selectedDate.format("YYYY-MM-DD"),
        };
        if (selectedMember && selectedMember.id) {
          payload.user_id = selectedMember.id;
        }
        if (selectedProject && selectedProject.value) {
          payload.project_id = selectedProject.value;
        }
        if (selectedTask && selectedTask.value) {
          payload.task_id = selectedTask.value;
        }
        if (selectedTimezone && selectedTimezone.timezone) {
          payload.timezone = selectedTimezone.timezone;
        }
        actionsReq(payload);
      }
    }
  }, [props.reloadScreenshotList]);

  const actionsReq = (payload) => {
    const user_id = localStorage.getItem("user_id");
    if (props.title === "Screenshots") {
      props.getScreenshotList(payload);
      props.getUserActivity(payload);
      props.getClockInOutList({
        organization_id: payload.organization_id,
        start_date: payload.date,
        end_date: payload.date,
        page: 1,
        tz: payload.timezone,
        user_id: payload.user_id || user_id,
      });
    } else if (props.title === "Apps") {
      props.getAppsList(payload);
    } else if (props.title === "URLs") {
      props.getUrlList(payload);
    } else if (props.title === "Location") {
      props.getLocationList(payload);
    }
  };

  const selectProject = (e) => {
    const { selectedOrganization } = props;
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedMember && e?.value) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.project_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      actionsReq(payload);
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
      setSelectedTask("");
    } else if ((e && !e.value) || e == null) {
      setSelectedMember("");
      setSelectedTask("");
      setSelectedProject(e);
      if (props.title === "Screenshots") {
        props.setScreenshotMemberId(null);
      }
    } else {
      setSelectedProject(e);
    }
  };

  const selectTask = (e) => {
    const { selectedOrganization } = props;
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (typeof selectedProject === "object" && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedMember) {
        payload.user_id = selectedMember.id;
      }
      if (e && e.value) {
        payload.task_id = e.value;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      actionsReq(payload);
    }
    setSelectedTask(e);
  };

  const selectTeamMember = (e) => {
    const { selectedOrganization } = props;
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        user_id: e.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (
        selectedTimezone &&
        selectedTimezone.type === "Member" &&
        e.timezone &&
        e.timezone.tz
      ) {
        payload.timezone = e.timezone.tz;
        setSelectedTimezone({
          type: "Member",
          timezone: e.timezone.tz,
          offset: e.timezone.offset,
        });
      } else if (
        selectedTimezone &&
        selectedTimezone.type === "Organization" &&
        selectedTimezone.timezone
      ) {
        payload.timezone = selectedTimezone.timezone;
      }
      setSelectedMember(e);
      if (props.title === "Screenshots") {
        props.setScreenshotMemberId(e.id);
      }
      actionsReq(payload);
    }
  };

  const clearMember = () => {
    const { selectedOrganization } = props;
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
        payload.timezone = selectedOrganization.timezone;
      }
      actionsReq(payload);
    }
    setSelectedMember("");
    if (props.title === "Screenshots") {
      props.setScreenshotMemberId(null);
    }
  };

  const selectDate = (date) => {
    const { selectedOrganization } = props;
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    setSelectedDate(date);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: date.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== "") {
        payload.user_id = selectedMember.id;
      }
      if (selectedTimezone && selectedTimezone.timezone) {
        payload.timezone = selectedTimezone.timezone;
      }
      actionsReq(payload);
    }
  };

  const singleDateChange = (direction) => {
    setSelectedDate((prevDate) => {
      const newDate =
        direction === "right"
          ? moment(prevDate).add(1, "days")
          : moment(prevDate).subtract(1, "days");
      selectDate(newDate);
      return newDate;
    });
  };

  const selectToday = () => {
    selectDate(moment());
  };

  const onTimezoneSelect = (timezone) => {
    const { selectedOrganization } = props;
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    setSelectedTimezone(timezone);
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        date: selectedDate.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedTask && selectedTask.value) {
        payload.task_id = selectedTask.value;
      }
      if (selectedMember && selectedMember.id !== "") {
        payload.user_id = selectedMember.id;
      }
      if (timezone && timezone.timezone) {
        payload.timezone = timezone.timezone;
      }
      actionsReq(payload);
    }
  };

  useEffect(() => {
    const member_id = localStorage.getItem("user_id");
    if (selectedOrganization && selectedOrganization.id) {
      if (selectedMember?.id || member_id) {
        getLiveMembersList &&
          getLiveMembersList({
            organization_id: selectedOrganization.id,
            user_id: selectedMember?.id ? selectedMember?.id : member_id,
          });
      }
    }
  }, [selectedMember, selectedOrganization]);

  return (
    <FilterSection
      style={{ marginTop: "30px" }}
      columns={"auto auto"}
      // justifyContent = 'flex-end'
      // justifyContent={props.title === "Location" && "space-between"}
    >
      <FilterInnerSection>
        <CommonFilter
          selectedProject={selectedProject}
          selectProject={selectProject}
          selectedTask={selectedTask}
          selectTask={selectTask}
          selectedMember={selectedMember}
          selectMember={selectTeamMember}
          clearMember={clearMember}
          columns={
            props.title === "Location" ? "180px 57px" : "180px 180px 57px"
          }
          isLocation={props.title === "Location"}
        />
        {/* </FilterInnerSection> */}
        {/* <FilterInnerSection
      // columns="1fr" columnGap = "200px"
      // columns={selectedTimezone ? "360px  140px" : "360px"}
      // columnGap="200px"
      // style={{ marginLeft: `auto` }}
      > */}
        <InputWithLabelSection>
          <FilterLabel>
            Date
            {getDateLabel({
              startDate: selectedDate,
              endDate: selectedDate,
              onlyToday: true,
            })}
          </FilterLabel>
          <CommonFlex gap="2px">
            <DatePicker
              id="activityFilterDate"
              date={selectedDate}
              onDateChange={selectDate}
              dateDisplayFormat={"DD/MM/YYYY"}
              placeholder={"Select date..."}
              allowPreviousDates
              disableFutureDates
            />

            <CommonFlex gap="0px" margin="1.5px 0 0 0">
              <IconArrow
                onClick={() => {
                  singleDateChange("left");
                }}
                padding="5px"
                size="40px"
                src={LeftArrow_3}
              />
              <IconArrow
                margin="0 0 0 2px"
                onClick={() => {
                  if (!isToday) {
                    singleDateChange("right");
                  }
                }}
                padding="5px"
                size="40px"
                src={RightArrow_3}
                disabled={isToday}
              />
            </CommonFlex>
            <div style={{ margin: "0 0 0 14px" }}>
              <FilterButton
                type="gray"
                padding="10px"
                onClick={() => selectToday()}
              >
                Today
              </FilterButton>
            </div>
          </CommonFlex>
        </InputWithLabelSection>
      </FilterInnerSection>
      <FilterInnerSection columns="auto">
        {selectedTimezone && props.title !== "Location" && (
          <InputWithLabelSection>
            <FilterLabel>Timezone</FilterLabel>
            <TimezoneDropdown
              selectedOrganization={props.selectedOrganization}
              selectedMember={selectedMember}
              selectTimezone={onTimezoneSelect}
              selectedTimezone={selectedTimezone}
              dropdownWidth="140px"
            />
          </InputWithLabelSection>
        )}
      </FilterInnerSection>
    </FilterSection>
  );
};

export default ActivityFilter;
