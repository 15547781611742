import { connect } from "react-redux";

// Component
import TimesheetWeekly from "./TimesheetWeekly";
// Actions
import {
  getDailyTimesheets,
  getOtherTimesheets,
  getWeeklyTimesheetDetails,
  clearTimesheetList,
  exportWeeklyTimeSheet,
} from "../timesheetActions";
import { getOrganizationMembersList } from "../../Projects/projectsActions";
import { getLiveMembersList } from "../../LiveFeed/liveFeedActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.timesheet.isLoading,
  dailyTimesheetLoading: state.timesheet.dailyTimesheetLoading,

  dailyTimesheets: state.timesheet.dailyList,
  timesheet: state.timesheet.list,
  timesheetDetails: state.timesheet.weeklyTimesheetDetails,

  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  projects: state.project,
  projectAssigneeList: state.project.projectAssigneeList,
  taskAssigneeList: state.project.taskAssigneeList,
  profileData: state.profile.profileData,

  liveMembersList: state.liveFeed.liveMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDailyTimesheets: (details) => dispatch(getDailyTimesheets({ ...details })),
  getOtherTimesheets: (details) => dispatch(getOtherTimesheets({ ...details })),
  getWeeklyTimesheetDetails: (details) =>
    dispatch(getWeeklyTimesheetDetails({ ...details })),
  exportWeeklyTimeSheet: (details) =>
    dispatch(exportWeeklyTimeSheet({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  clearTimesheetList: () => dispatch(clearTimesheetList()),
  getLiveMembersList: (payload) => dispatch(getLiveMembersList(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWeekly);
